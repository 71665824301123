import api from './api';

const API_PREFIX = "api/alerts-reports";

// Utility function to make API requests for alerts and reports
const fetchAlertReport = async (path, method, body = {}, params = {}) => {
    const url = path
    const response = await api[method](url, body, { params });
    return response;
};

// Fetch all alerts and reports
const getAllAlertsReports = async (params = {}) => {
    const response = await api.get(`${API_PREFIX}/all`, { params });
	return response;   
};

// Save a new alert or report
const saveAlertReport = async (body = {}) => {
    return fetchAlertReport(API_PREFIX, 'post', body);
};

// Save a new custom alert or report
const saveCustomAlertReport = async (body = {}) => {
    return fetchAlertReport(`${API_PREFIX}/custom`, 'post', body);
};


// Update an existing alert or report by ID
const updateAlertReportData = async (id, body = {}) => {
    if (!id) {
        throw new Error('ID is required to update an alert or report.');
    }
    return fetchAlertReport(`${API_PREFIX}/${id}`, 'put', body);
};

export {
    getAllAlertsReports,
    saveAlertReport,
    updateAlertReportData,
    saveCustomAlertReport
};