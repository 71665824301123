import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PAGE_TYPE } from "../../../../types/pages.type";
import { itemPercentage, projectionPerMonth } from "../../../../utilis/common";
import { calculateDiffBetweenPercentages, pickComparisonColor } from "../../../../utilis/percentage-comparison";
import InfoIcon from "../../../icon/InfoIcon";
import ColorBox from "../../../shared/dashboard/ColorBox";
import HtmlTooltip from "../../../shared/HtmlTooltip";
import CardTooltipContent from "../../card/CardTooltipContent";
import styles from "./TotalCountCircle.module.scss";
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import ShowChartIcon from "@mui/icons-material/ShowChart";
import BusinessIcon from "@mui/icons-material/Business";
import { CHART_TAB_BUTTON } from "../../../../types/chart-dialog.type";


const TotalCountCircle = ({
	dbData,
	dbDataComparison,
	admissionCompareAgainst,
	admissionCompareAgainstComparison,
	total,
	totalComparison,
	totalPercentage = 0,
	totalComparisonPercentage = 0,
	totalProjectionPercentage = 0,
	totalProjectionComparisonPercentage = 0,
	totalTitle,
	projectTotal = 0,
	projectTotalComparison,
	projectTitle,
	handleClickTotal,
	filter,
	filterComparison,
	page,
	projectionBgColor,
	tooltipContent,
	handleOnClickAction,
	selected,
	loading,
	handleOnClickReport,
	comparingAgainstDifferentFacility,
	projectionDays,
}) => {
	const handleClickOnDetails = useCallback(
		(event, totalTitle, defaultTab) => {
			event.stopPropagation();
			handleOnClickAction(totalTitle, defaultTab);
		},
		[handleOnClickAction]
	);
	const [comparingAgainstScaled, setComparingAgainstScaled] = useState(null);
	const [comparingAgainst, setComparingAgainst] = useState("");
	const [, setComparingAgainstProjection] = useState("");
	const percentageThresholds = useSelector((state) => state.comparison.percentageThresholds);
	const specialPages = [PAGE_TYPE.ADMISSION];
	const isSpecialPage = specialPages.find((x) => x === page)?.length > 0;
	const [comparisonDiffColor, setComparisonDiffColor] = useState(null);
	const [numberOfDays, setNumberOfDays] = useState(null);
	const projectTotalComparisonSpecial = projectionPerMonth(totalComparison, filterComparison, projectionDays);
	const activeFacilities = useSelector((state) => state.activeFacilities.facilities);

	useEffect(() => {
		const totalSpecialPage = itemPercentage(total, admissionCompareAgainst, "number");
		const totalComparisonSpecialPage = itemPercentage(totalComparison, admissionCompareAgainstComparison, "number");
		const {
			percentageDiff,
			itemTotalComparisonScaled,
			numberOfDays: numberOfDaysTemp,
		} = isSpecialPage
				? calculateDiffBetweenPercentages(
					totalSpecialPage,
					totalComparisonSpecialPage,
					true,
					filter,
					filterComparison,
					totalComparison,
					projectionDays
				)
				: calculateDiffBetweenPercentages(
					totalPercentage,
					totalComparisonPercentage,
					false,
					filter,
					filterComparison,
					totalComparison,
					projectionDays
				);

		setNumberOfDays(numberOfDaysTemp);
		setComparingAgainstScaled(itemTotalComparisonScaled);
		setComparingAgainst(totalComparison);
		setComparingAgainstProjection(projectTotalComparison);

		const percentageChange = pickComparisonColor(percentageDiff, isSpecialPage);
		setComparisonDiffColor(percentageChange);
	}, [
		projectionDays,
		totalPercentage,
		totalProjectionComparisonPercentage,
		totalProjectionPercentage,
		totalComparisonPercentage,
		page,
		projectTotal,
		total,
		projectTotalComparison,
		totalComparison,
		isSpecialPage,
		projectTotalComparisonSpecial,
		filter,
		filterComparison,
		percentageThresholds,
		comparingAgainstDifferentFacility,
		dbData?.bedCapacity,
		dbDataComparison?.bedCapacity,
		admissionCompareAgainst,
		admissionCompareAgainstComparison,
	]);

	return (
		<>
			<div className={classNames("df", "acc", "p-t-95", "p-b-30", styles.totalCountCircle, loading && styles.skeleton)}>
				<div
					className={classNames(
						"df",
						"acc",
						"m-r-20",
						styles.circle,
						styles.totalCircle,
						selected && styles.selected,
						loading && "skeleton",
						!loading && page === "admission" ? styles.totalCircleAdmission : "",
						!loading && page === "hospital" ? styles.totalCircleHospital : "",
						!loading && page === PAGE_TYPE.COMMUNITY_TRANSFER ? styles.communityTransfer : "",
						!loading && page === PAGE_TYPE.DECEASED ? styles.deceased : "",
						!loading && page === PAGE_TYPE.OVERALL ? styles.deceased : ""
					)}
					onClick={handleClickTotal}
				>
					<div className={classNames("df", "ffc", "acc", styles.innerCircle, selected && styles.selected)}>
						{!loading && (
							<div className={`${styles.htmlTooltip} ${activeFacilities.length > 1 && styles.multipleFacility}`}>
								<HtmlTooltip content={<CardTooltipContent content={tooltipContent} />}>
									<IconButton disableFocusRipple={true} disableRipple={true} size="small">
										<InfoIcon />
									</IconButton>
								</HtmlTooltip>
							</div>
						)}
						{handleOnClickReport && !loading && (
							<div className={`cp ${styles.reportIcon} ${activeFacilities.length > 1 && styles.multipleFacility}`} onClick={(e) => handleClickOnDetails(e, totalTitle)}>
								<Tooltip title={"Generate Report"} arrow>
									<IconButton
										onClick={(e) => {
											e.preventDefault();
											e.stopPropagation();
											handleOnClickReport(totalTitle);
										}}
										disableFocusRipple={true}
										disableRipple={true}
										size="small"
									>
										<FileUploadOutlinedIcon
											fontSize="small"
											sx={{
												cursor: "pointer",
												fill: "#637381",
												transition: "fill 0.2s ease-in-out",
												"&:hover": { fill: "#9ea7b3" }
											}}
										/>
									</IconButton>
								</Tooltip>
							</div>
						)}
						{handleOnClickAction && !loading && (
							<>
								<div className={`cp ${styles.listIcon} ${activeFacilities.length > 1 && styles.multipleFacility}`} onClick={(e) => handleClickOnDetails(e, totalTitle, CHART_TAB_BUTTON.TABLE)}>
									<Tooltip title={"Resident List"} arrow>
										<PeopleOutlineIcon
											fontSize="small"
											sx={{
												cursor: "pointer",
												fill: "#637381",
												transition: "fill 0.2s ease-in-out",
												"&:hover": { fill: "#9ea7b3" }
											}}
										/>
									</Tooltip>
								</div>
								<div className={`cp ${styles.chartIcon} ${activeFacilities.length > 1 && styles.multipleFacility}`} onClick={(e) => handleClickOnDetails(e, totalTitle, CHART_TAB_BUTTON.CHART)}>
									<Tooltip
										title={"Chart View"}
										arrow
									>
										<ShowChartIcon
											fontSize="small"
											sx={{
												cursor: "pointer",
												fill: "#637381",
												transition: "fill 0.2s ease-in-out",
												"&:hover": { fill: "#9ea7b3" }
											}}
										/>
									</Tooltip>
								</div>
								{activeFacilities && activeFacilities.length > 1 && (
									<div className={`cp ${styles.buildingIcon}`} onClick={(e) => handleClickOnDetails(e, totalTitle, CHART_TAB_BUTTON.BUILDING)}>
										<Tooltip title={"Building View"} arrow>
											<BusinessIcon
												fontSize="small"
												sx={{
													cursor: "pointer",
													fill: "#637381",
													transition: "fill 0.2s ease-in-out",
													"&:hover": { fill: "#9ea7b3" }
												}}
											/>
										</Tooltip>
									</div>
								)}
							</>
						)}

						<Box
							display={"flex"}
							alignItems={"center"}
							justifyContent={"center"}
							className={`ffmar-bold fw700 fs65`}
						// sx={{ width: "65px", height: "73.5px" }}
						>
							{loading ? (
								<span className="skeleton-text">0</span>
							) : (
								Math.round(projectionDays === null ? total : projectTotal)
							)}
							{!loading && (
								<ColorBox
									color={comparisonDiffColor}
									comparingAgainst={comparingAgainst}
									comparingAgainstScaled={comparingAgainstScaled}
									numberOfDays={numberOfDays}
								/>
							)}
						</Box>
						<Typography variant="subtitle1" sx={{ whiteSpace: "pre-wrap", textAlign: "center" }}>
							{totalTitle}
						</Typography>
					</div>
				</div>
				{page !== PAGE_TYPE.OVERALL && (
					<div
						className={classNames(
							"df",
							"acc",
							styles.circle,
							styles.projection,
							selected ? styles.selected : null,
							!loading && styles.projectionColor,
							loading ? "skeleton" : ""
						)}
						{...(projectionBgColor
							? { style: { background: projectionBgColor, alignSelf: "flex-end" } }
							: { style: { alignSelf: "flex-end" } })}
						onClick={handleClickTotal}
					>
						<div className={classNames("df", "ffc", "acc", styles.innerCircle, styles.selected)}>
							<Box
								display={"flex"}
								alignItems={"center"}
								justifyContent={"center"}
								className={`ffmar-bold fw700 fs49`}
							// sx={{ width: "65px", height: "73.5px" }}
							>
								{loading ? (
									<span className="skeleton-text" style={{ fontStyle: "italic" }}>
										0%
									</span>
								) : (
									<span style={{ fontStyle: "italic" }}>{`${projectionDays === null ? totalPercentage : totalProjectionPercentage
										}%`}</span>
								)}
								{/* {!loading && (
								<ColorBox
									color={comparisonDiffColor}
									comparingAgainst={comparingAgainstProjection}
									comparingAgainstScaled={comparingAgainstScaled}
									numberOfDays={numberOfDays}
								/>
							)} */}
							</Box>
							<Typography variant="subtitle2">{projectTitle}</Typography>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default TotalCountCircle;
