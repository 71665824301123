import { store } from "..";
import { setFilterDBData, setMainTotal } from "../store/reducers/hospital.slice";
import {
	setFilterDBData as setFilterDBDataComparison,
	setMainTotal as setMainTotalComparison,
} from "../store/reducers/comparisonReducers/hospitalComparison.slice";
import { getCardPatientAndChartData, getCardPatientChartData, getAllCount } from "./api/hospital.api";
import { batch } from "react-redux";

const ongoingPatientADTRequests = new Map();

const cardPatientAndChartData = async (data, type = null) => {
	const requestKey = JSON.stringify({ type });

	// Check for duplicate requests
	// Check for duplicate requests
	if (ongoingPatientADTRequests.has(requestKey)) {
		const { controller } = ongoingPatientADTRequests.get(requestKey);
		controller.abort(); // Abort the ongoing request
		ongoingPatientADTRequests.delete(requestKey); // Clean up the old request
	}

	const controller = new AbortController();
	const signal = controller.signal;

	try {
		const requestPromise = getCardPatientAndChartData(data, { signal });
		ongoingPatientADTRequests.set(requestKey, { requestPromise, controller });

		const res = await requestPromise;

		return res;
	} catch (e) {
		console.log(e);
		if (e.name === "AbortError") {
			console.log("Request aborted:", requestKey);
		} else {
			console.log(e);
			throw e; // Rethrow other errors
		}
		// throw e; // Rethrow the error if needed
	} finally {
		// Remove the request from tracking once it completes (success or failure)
		ongoingPatientADTRequests.delete(requestKey);
	}
};

const cardPatientChartData = async (data = null) => {
	try {
		const res = await getCardPatientChartData(data);
		return res;
	} catch (e) {
		console.log(e);
	}
};
const ongoingPatientRequests = new Map();

const getAllPatientCount = async (data = null, forComparison = null, type = null) => {

	const requestKey = JSON.stringify({ type });

	// Check for duplicate requests
	if (ongoingPatientRequests.has(requestKey)) {
		const { controller } = ongoingPatientRequests.get(requestKey);
		controller.abort(); // Abort the ongoing request
		ongoingPatientRequests.delete(requestKey); // Clean up the old request

	}

	// Create a new AbortController for the new request
	const controller = new AbortController();
	const signal = controller.signal;

	try {

		const requestPromise = getAllCount(data, { signal });
		ongoingPatientRequests.set(requestKey, { requestPromise, controller });

		const res = await requestPromise;
		batch(() => {
		store.dispatch(forComparison ? setFilterDBDataComparison(res?.totals) : setFilterDBData(res?.totals));
		store.dispatch(forComparison ? setMainTotalComparison(res?.totals?.total) : setMainTotal(res?.totals?.total));
		});
		return res;
	} catch (e) {
		console.log(e);
		if (e.name === "AbortError") {
			console.log("Request aborted:", requestKey);
		} else {
			console.log(e);
			throw e; // Rethrow other errors
		}
		// throw e; // Rethrow the error if needed
	} finally {
		// Remove the request from tracking once it completes (success or failure)
		ongoingPatientRequests.delete(requestKey);
	}
};

// const getAllPatientCount = async (data = null, forComparison = null) => {
// 	try {

// 		const res = await getAllCount(data);
// 		store.dispatch(forComparison ? setFilterDBDataComparison(res?.totals) : setFilterDBData(res?.totals));
// 		store.dispatch(forComparison ? setMainTotalComparison(res?.totals?.total) : setMainTotal(res?.totals?.total));
// 		return res;
// 	} catch (e) {
// 		console.log(e);
// 	}
// };

export { cardPatientAndChartData, cardPatientChartData, getAllPatientCount };
