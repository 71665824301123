import BusinessIcon from "@mui/icons-material/Business";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { CHART_TAB_BUTTON } from "../../../types/chart-dialog.type";
import { useSelector } from "react-redux";
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';

const CommonIconButtons = ({ loading, title, handleOnClickAction }) => {
    const activeFacilities = useSelector((state) => state.activeFacilities.facilities);

    const buttonStyles = {
        width: 23,
        height: 23,
        mt: 0,
        mr: 0.7,
        borderRadius: "50%", // Makes the button circular
        border: loading ? "none" : "1px solid grey",
        color: loading ? "inherit" : "black",
        ...(loading
            ? {}
            : {
                ":hover": {
                    backgroundColor: "grey",
                    color: "#fff"
                },
                cursor: "pointer",
            }),
    };

    const buttonConfig = [
        { title: "Resident List", icon: <PeopleOutlineIcon sx={{ width: 18, height: 18 }} />, action: null },
        { title: "Chart View", icon: <ShowChartIcon sx={{ width: 18, height: 18 }} />, action: CHART_TAB_BUTTON.CHART },
        ...activeFacilities && activeFacilities.length > 1
            ? [{ title: "Building View", icon: <BusinessIcon sx={{ width: 16, height: 16 }} />, action: CHART_TAB_BUTTON.BUILDING }]
            : []  // If the condition is false, return an empty array to keep the config unchanged
    ];

    return (
        <div className={!loading && "cp"}>
            <>
                {buttonConfig.map(({ title, icon, action }, index) => (
                    <Tooltip key={index} title={title} arrow>
                        <IconButton
                            disableFocusRipple
                            disableRipple
                            sx={{
                                ...buttonStyles,
                                ...(title === "Building View" && { marginRight: 0 }) // Remove marginRight when title is "Building View"
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleOnClickAction(title, action)
                            }}
                            className={loading && "skeleton"}
                        >
                            {!loading && icon}
                        </IconButton>
                    </Tooltip>
                ))}
            </>
        </div>
    );
};

export default CommonIconButtons;
