/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Grid, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { closeDetailsDialog } from "../../../store/reducers/admission.slice";
import { cardPatientAndChartData } from "../../../services/admission.service";
import _ from "lodash";
import CardDetailChart from "../../shared/chart-dialog-common/chart-tab/CardDetailChart";
import {
	chartFilterPermission,
	chartGroupBy,
	chartsData,
	dayDifferent,
	getAdmissionFieldNames,
	isUpdateDefaultFilter,
} from "../../../utilis/charts-common";
import { CHART_FILTER_DAY_OF, DASHBOARD_FILTER_TYPE, FILTER_TYPES } from "../../../types/common.type";
import { HOSPITAL_CARDS_TYPE } from "../../../types/hospital.type";
import { CHART_POINT_TYPE, CHART_TAB_BUTTON } from "../../../types/chart-dialog.type";
import ChartDialogLeftSidebar from "../../shared/chart-dialog-common/ChartDialogLeftSidebar";
import ChartDetailsTab from "../../shared/chart-dialog-common/ChartDetailsTab";
import ChartTabLoader from "../../shared/chart-dialog-common/ChartTabLoader";
import ChartDialogContainer from "../../shared/chart-dialog-common/ChartDialogContainer";
import ChartTableList from "../../shared/chart-dialog-common/chart-table/ChartTableList";
import { DEFAULT_CHART_DATA, DEFAULT_CHART_FILTER } from "../../../data/common.data";
import { ADMISSION_CARDS_TYPE } from "../../../types/admission.type";
import ChartBuildingList from "../../shared/chart-dialog-common/chart-building/ChartBuildingList";
import { getAccountQuestions } from "../../../services/api/user.api";
import { getChartFacilityPercentageBy, patientDataOrderBy } from "../../../utilis/common";
import { PAGE_TYPE } from "../../../types/pages.type";
import ChartDetailsSearchBar, { tableDefaultQuestions } from "../../shared/chart-dialog-common/ChartDetailsSearchBar";
import NoteContainer from "../../shared/NoteContainer";

export default function AdmissionCardDetailsDialog({ handleOnClickReport, handleGenerateExcelReport }) {
	const tableElementRef = useRef();
	const buildingElementRef = useRef();
	const [selectedItem, setSelectedItem] = useState([]);
	const [loading, setLoading] = useState(false);
	const [buttonFilterType, setButtonFilterType] = useState(CHART_FILTER_DAY_OF.DAY);
	const [patientData, setPatientData] = useState([]);
	const [censusAverage, setCensusAverage] = useState([]);
	const [censusByPeriod, setCensusByPeriod] = useState([]);
	const [queryFilters, setQueryFilters] = useState(DEFAULT_CHART_FILTER);
	
	const [defaultPatientData, setDefaultPatientData] = useState([]);
	const [filterData, setFilterData] = useState([]);
	const [chartData, setChartData] = useState(DEFAULT_CHART_DATA);
	const dispatch = useDispatch();
	const [selectedFilter, setSelectedFilter] = useState(FILTER_TYPES.DAILY);
	const {
		detailsDialog: { isOpen, title, data, filters, dbData },
	} = useSelector((state) => state.admission);
	const [selectedTab, setSelectedTab] = useState(filters?.defaultTab ? filters?.defaultTab : CHART_TAB_BUTTON.TABLE);
	const [isShowPercentage, setIsShowPercentage] = useState(false);
	const [censusByFacility, setCensusByFacility] = useState([]);
	const [bedCapacityByFacility, setBedCapacityByFacility] = useState([]);
	const [orderBy, setOrderBy] = useState('dateOfADT'); // Default sort column
	const [order, setOrder] = useState('asc'); // Default sort direction
	// COMPARISON STATES ---------->
	const {
		detailsDialog: { data: dataComparison, filters: filtersComparison },
	} = useSelector((state) => state.admissionComparison);
	const [chartDataComparison, setChartDataComparison] = useState(DEFAULT_CHART_DATA);
	const [censusAverageComparison, setCensusAverageComparison] = useState([]);
	const [censusByPeriodComparison, setCensusByPeriodComparison] = useState([]);
	const [censusByFacilityComparison, setCensusByFacilityComparison] = useState([]);
	const [bedCapacityByFacilityComparison, setBedCapacityByFacilityComparison] = useState([]);
	const [queryFiltersComparison, setQueryFiltersComparison] = useState(DEFAULT_CHART_FILTER);
	const [defaultPatientDataComparison, setDefaultPatientDataComparison] = useState([]);
	const [filterDataComparison, setFilterDataComparison] = useState([]);
	const [selectedItemComparison, setSelectedItemComparison] = useState([]);
	const { rangesSet } = useSelector((state) => state.comparison);

	const { auth } = useSelector(({ auth }) => ({ auth }));
	const [questions, setQuestions] = React.useState([]);
	const activeFacilities = useSelector((state) => state.activeFacilities.facilities);

	const [selectedFacility, setSelectedFacility] = useState(activeFacilities);

	const getQuestions = async (activeFacilitiesData) => {
		const res = await getAccountQuestions({
			facilityid: activeFacilitiesData,
			accountId: auth.accountId,
			pageType: DASHBOARD_FILTER_TYPE.ADMISSION
		})
		if (res && res.length > 0) {
			setQuestions(res);
		}
	};

	useEffect(() => {
		getQuestions(activeFacilities);
		// eslint-disable-next-line
	}, [activeFacilities]);

	// * added comparison
	const updateChartArrData = async (dataArr, filterType = null, type = null, filter, forComparison) => {
		try {
			let filterBy = filterType;
			if (!filterType) {
				filterBy = buttonFilterType;
			}
			let chartDataArr = [];
			if (type == "filter" && filter) {
				chartDataArr = await chartsData(dataArr, filter);
			} else {
				chartDataArr = await chartsData(dataArr, queryFilters);
			}
			let filtersLatest = filter ? filter : forComparison ? queryFiltersComparison : queryFilters;
			const latestChartData = await chartGroupBy(
				chartDataArr,
				filterBy,
				forComparison ? censusAverage : censusAverageComparison,
				forComparison ? censusByPeriod : censusByPeriodComparison,
				filtersLatest
			);

			if (type == "filter") {
				forComparison
					? setChartDataComparison((prevState) => ({
						...prevState,
						filterData: latestChartData,
						filterPatients: dataArr,
					}))
					: setChartData((prevState) => ({
						...prevState,
						filterData: latestChartData,
						filterPatients: dataArr,
					}));
			} else {
				forComparison
					? setChartDataComparison((prevState) => ({
						...prevState,
						filterData: latestChartData,
					}))
					: setChartData((prevState) => ({
						...prevState,
						filterData: latestChartData,
					}));
			}
		} catch (e) {
			console.log(e);
		}
	};

	// * added comparison
	// ! Not sure if comparison needs to be extended to setPatientData. If issues, check here first.
	const filterOptions = async (newChecked, mainDataArr, latestButtonFilterType = null, filterObject, forComparison) => {
		setPatientData([]);
		let mainData = mainDataArr;
		if (activeFacilities.length > 1) {
			const filterPatientsByFacility = _.filter(mainData, ({ facilityId }) => _.every([_.includes(selectedFacility, facilityId)]));
			mainData = filterPatientsByFacility
		}
		if (filters.type === HOSPITAL_CARDS_TYPE.DX_DATA) {
			let newFilters = _.filter(defaultPatientData, ({ dxIds }) => {
				const matchedIds = _.intersection(newChecked, dxIds);
				if (matchedIds.length > 0) {
					return true;
				} else {
					return false;
				}
			});
			setPatientData(newFilters);
			let newChartFilters = _.filter(mainData, ({ dxIds }) => {
				const matchedIds = _.intersection(newChecked, dxIds);
				if (matchedIds.length > 0) {
					return true;
				} else {
					return false;
				}
			});
			await updateChartArrData(newChartFilters, latestButtonFilterType, "filter", filterObject, forComparison);
		} else if (filters.type === HOSPITAL_CARDS_TYPE.NINETY_DAYS_DATA) {
			let ninetyDaysDataIds = [];
			let ninetyDaysDataFilter = _.filter(data, ({ _id }) => _.every([_.includes(newChecked, _id)]));
			if (ninetyDaysDataFilter && ninetyDaysDataFilter.length > 0) {
				ninetyDaysDataFilter.map((item) => (ninetyDaysDataIds = [...ninetyDaysDataIds, ...item.ids]));
			}
			let newFilters = _.filter(defaultPatientData, ({ _id }) => _.every([_.includes(ninetyDaysDataIds, _id)]));
			setPatientData(newFilters);
			let newChartFilters = _.filter(mainData, ({ _id }) => _.every([_.includes(ninetyDaysDataIds, _id)]));
			await updateChartArrData(newChartFilters, latestButtonFilterType, "filter", filterObject, forComparison);
		} else if (filters.type === HOSPITAL_CARDS_TYPE.DAYS_DATA) {
			let newFilters = _.filter(defaultPatientData, ({ day }) => {
				return _.every([_.includes(newChecked, day)]);
			});
			setPatientData(newFilters);

			let newChartFilters = _.filter(mainData, ({ day }) => {
				return _.every([_.includes(newChecked, day)]);
			});
			await updateChartArrData(newChartFilters, latestButtonFilterType, "filter", filterObject, forComparison);
		} else if (filters.type === HOSPITAL_CARDS_TYPE.SHIFT_DATA) {
			let newFilters = _.filter(defaultPatientData, ({ shiftName }) => {
				return _.every([_.includes(newChecked, shiftName)]);
			});
			setPatientData(newFilters);
			let newChartFilters = _.filter(mainData, ({ shiftName }) => {
				return _.every([_.includes(newChecked, shiftName)]);
			});
			await updateChartArrData(newChartFilters, latestButtonFilterType, "filter", filterObject, forComparison);
		} else if (filters.type === HOSPITAL_CARDS_TYPE.HOSPITALIZATIONS) {
			if (newChecked.length === 1) {
				let filter = Object();
				if (newChecked[0] == "newHospitalizations") {
					filter.reHospitalization = false;
				}
				if (newChecked[0] == "reHospitalizations") {
					filter.reHospitalization = true;
				}
				let newFilters = _.filter(defaultPatientData, filter);
				setPatientData(newFilters);
				let newChartFilters = _.filter(mainData, filter);

				await updateChartArrData(newChartFilters, latestButtonFilterType, "filter", filterObject, forComparison);
			} else {
				setPatientData(defaultPatientData);
				await updateChartArrData(mainData, latestButtonFilterType, "filter", filterObject, forComparison);
			}
		} else if (filters.type === HOSPITAL_CARDS_TYPE.DCER_DATA) {
			if (newChecked.length === 1) {
				let filter = Object();
				if (newChecked[0] === "DC") {
					filter.wasAdmitted = true;
				}
				if (newChecked[0] === "ER") {
					filter.wasAdmitted = false;
				}
				let newFilters = _.filter(defaultPatientData, filter);
				setPatientData(newFilters);
				let newChartFilters = _.filter(mainData, filter);
				await updateChartArrData(newChartFilters, latestButtonFilterType, "filter", filterObject, forComparison);
			} else {
				setPatientData(defaultPatientData);
				await updateChartArrData(mainData, latestButtonFilterType, "filter", filterObject, forComparison);
			}
		} else if (filters.type === HOSPITAL_CARDS_TYPE.RETURNS_DATA) {
			let filter = Object();
			if (newChecked.length === 1) {
				if (newChecked[0] === "Returned") {
					filter.wasReturned = true;
				}
				if (newChecked[0] === "Didn't Return") {
					filter.wasReturned = false;
				}
				let newFilters = _.filter(defaultPatientData, filter);
				setPatientData(newFilters);
				let newChartFilters = _.filter(mainData, filter);
				await updateChartArrData(newChartFilters, latestButtonFilterType, "filter", filterObject, forComparison);
			} else {
				setPatientData(defaultPatientData);
				await updateChartArrData(mainData, latestButtonFilterType, "filter", filterObject, forComparison);
			}
		} else {
			
			let newFilters = filters?.isTotalCard ? defaultPatientData : _.filter(defaultPatientData, ({ filterId }) => {
				return _.every([_.includes(newChecked, filterId)]);
			});
			setPatientData(newFilters);

			let newChartFilters = filters?.isTotalCard ? mainData : _.filter(mainData, ({ filterId }) => {
				return _.every([_.includes(newChecked, filterId)]);
			});
			await updateChartArrData(newChartFilters, latestButtonFilterType, "filter", filterObject, forComparison);
		}
	};

	useMemo(() => {
		filterOptions(selectedItem,
			chartData?.mainData,
			buttonFilterType,
			{
				...filters?.filter,
			},
			undefined,
			undefined,
			false
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedFacility]);

	// * added comparison
	const getPatientChartsData = async () => {
		try {
			setLoading(true);
			if (
				filters.type === ADMISSION_CARDS_TYPE.TOTAL ||
				filters.type === ADMISSION_CARDS_TYPE.READMISSION ||
				filters.type === ADMISSION_CARDS_TYPE.ADMISSION
			) {
				setIsShowPercentage(true);
			}

			const [response, responseComparison] = await Promise.all([
				cardPatientAndChartData({ ...filters }, "main"),
				filtersComparison && cardPatientAndChartData({ ...filtersComparison }, "comparison"),
			]);
			let latestButtonFilterType = buttonFilterType;
			const isUpdateFilter = isUpdateDefaultFilter(filters.filter);
			if (isUpdateFilter) {
				setSelectedFilter(FILTER_TYPES.WEEKLY);
				latestButtonFilterType = CHART_FILTER_DAY_OF.WEEK;
				setButtonFilterType(CHART_FILTER_DAY_OF.WEEK);
			}
			const responseData = response?.data?.map((ele) => { return { ...ele, facilityName: ele?.facility?.name } }) ?? []
			if (response && responseData) {
				setCensusAverage(response.censusAverage);
				setCensusByPeriod(response?.censusByPeriod || []);
				const censusByData = await getChartFacilityPercentageBy(response)
				setCensusByFacility(censusByData);				
				setPatientData(responseData);
				setDefaultPatientData(responseData);
				const chartDataArr = await chartsData(responseData, filters.filter);
				const latestChartData = await chartGroupBy(
					chartDataArr,
					latestButtonFilterType,
					response.censusAverage,
					response?.censusByPeriod,
					filters.filter
				);
				setChartData({
					mainData: responseData,
					filterPatients: responseData,
					filterData: latestChartData,
				});
			}

			setFilterData(data);
			if (filters.selectedFilterData && filters.selectedFilterData.length > 0) {
				setSelectedItem([...filters.selectedFilterData]);
				filterOptions(filters.selectedFilterData, responseData, latestButtonFilterType, filters.filter);
			} else {
				const selectedIds = _.map(data, "_id") || [];
				setSelectedItem(selectedIds);
			}

			if (
				filters.type === ADMISSION_CARDS_TYPE.TOTAL ||
				filters.type === ADMISSION_CARDS_TYPE.READMISSION ||
				filters.type === ADMISSION_CARDS_TYPE.ADMISSION
			) {
				setIsShowPercentage(true);
			}

			// COMPARISON BLOCK
			if (responseComparison && responseComparison.data) {
				setCensusAverageComparison(responseComparison.censusAverage);
				setCensusByPeriodComparison(responseComparison.censusByPeriod || []);

				if (responseComparison.censusByFacility) {
					setCensusByFacilityComparison(responseComparison.censusByFacility || []);
				}

				if (responseComparison.bedCapacityByFacility) {
					setBedCapacityByFacilityComparison(responseComparison.bedCapacityByFacility);
				}

				const responseDataComparison = responseComparison.data;
				setDefaultPatientDataComparison(responseDataComparison);
				const chartDataArr = await chartsData(responseDataComparison, filtersComparison?.filter);
				const latestChartData = await chartGroupBy(
					chartDataArr,
					latestButtonFilterType,
					responseComparison.censusAverage,
					responseComparison.censusByPeriod,
					filtersComparison?.filter
				);
				setChartDataComparison({
					mainData: responseDataComparison,
					filterPatients: responseDataComparison,
					filterData: latestChartData,
				});
			}

			setFilterDataComparison(dataComparison);
			if (filtersComparison?.selectedFilterData && filtersComparison?.selectedFilterData.length > 0) {
				setSelectedItemComparison([...filtersComparison?.selectedFilterData]);
				filterOptions(
					filtersComparison?.selectedFilterData,
					responseComparison.data,
					latestButtonFilterType,
					filtersComparison?.filter,
					true
				);
			} else {
				const selectedIds = _.map(dataComparison, "_id") || [];
				setSelectedItemComparison(selectedIds);
			}
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	};

	// * added comparison
	const reloadChartData = useCallback(
		async (startDate, endDate, startDateComparison, endDateComparison) => {
			try {
				setLoading(true);
				let filterObj = { ...filters, filter: { startDate, endDate } };
				let filterObjComparison = {
					...filtersComparison,
					filter: { startDate: startDateComparison, endDate: endDateComparison },
				};

				let latestButtonFilterType = buttonFilterType;
				const isUpdateFilter = isUpdateDefaultFilter(filterObj.filter);

				if (isUpdateFilter) {
					setSelectedFilter(FILTER_TYPES.WEEKLY);
					latestButtonFilterType = CHART_FILTER_DAY_OF.WEEK;
					setButtonFilterType(CHART_FILTER_DAY_OF.WEEK);
				}
				const [response, responseComparison] = await Promise.all([
					cardPatientAndChartData({ ...filterObj }),
					filterObjComparison && cardPatientAndChartData({ ...filterObjComparison }),
				]);
				const responseData = response?.data?.map((ele) => { return { ...ele, facilityName: ele?.facility?.name } }) ?? []
				if (response && responseData && responseData.length > 0) {
					setCensusAverage(response.censusAverage);
					setCensusByPeriod(response?.censusByPeriod || []);
					const censusByData = await getChartFacilityPercentageBy(response)
					setCensusByFacility(censusByData);					
					const chartDataArr = await chartsData(responseData, filterObj.filter);
					const latestChartData = await chartGroupBy(
						chartDataArr,
						latestButtonFilterType,
						response.censusAverage,
						response?.censusByPeriod,
						filterObj.filter
					);

					setChartData({
						mainData: responseData,
						filterPatients: responseData,
						filterData: latestChartData,
					});
					if (selectedItem.length > 0) {
						await filterOptions(selectedItem, responseData, latestButtonFilterType, filterObj.filter);
					}
				}

				if (responseComparison && responseComparison.data && responseComparison.data.length > 0) {
					setCensusAverageComparison(responseComparison.censusAverage);
					setCensusByPeriodComparison(responseComparison.censusByPeriod || []);

					if (responseComparison.censusByFacility) {
						setCensusByFacilityComparison(responseComparison.censusByFacility || []);
					}

					if (responseComparison.bedCapacityByFacility) {
						setBedCapacityByFacilityComparison(responseComparison.bedCapacityByFacility);
					}

					const chartDataArrComparison = await chartsData(responseComparison.data, filterObjComparison.filter);
					const latestChartDataComparison = await chartGroupBy(
						chartDataArrComparison,
						latestButtonFilterType,
						responseComparison.censusAverage,
						responseComparison.censusByPeriod,
						filterObjComparison.filter
					);

					setChartDataComparison({
						mainData: responseComparison.data,
						filterPatients: responseComparison.data,
						filterData: latestChartDataComparison,
					});
					if (selectedItem.length > 0) {
						await filterOptions(
							selectedItemComparison,
							responseComparison.data,
							latestButtonFilterType,
							filterObjComparison.filter,
							true
						);
					}
				}
			} catch (e) {
				console.log(e);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[buttonFilterType, selectedItem, selectedItemComparison]
	);

	// * added comparison
	const reset = () => {
		setSelectedItem([]);
		setPatientData([]);
		setDefaultPatientData([]);
		setFilterData([]);

		setSelectedItemComparison([]);
		setDefaultPatientDataComparison([]);
		setFilterDataComparison([]);
	};

	// * added comparison
	const handleToggle = useCallback(
		async (value) => {
			const currentIndex = selectedItem.indexOf(value);
			const newChecked = [...selectedItem];
			if (currentIndex === -1) {
				newChecked.push(value);
			} else {
				newChecked.splice(currentIndex, 1);
			}
			if (newChecked.length > 0) {
				await Promise.all([
					filterOptions(newChecked, chartData.mainData, buttonFilterType),
					filterOptions(newChecked, chartDataComparison.mainData, buttonFilterType, undefined, true),
				]);
			} else {
				setPatientData([]);
				setChartData((prevState) => ({
					...prevState,
					filterData: [],
					filterPatients: [],
				}));
				setChartDataComparison((prevState) => ({
					...prevState,
					filterData: [],
					filterPatients: [],
				}));
			}
			setSelectedItem(newChecked);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selectedItem, chartData.mainData, buttonFilterType, chartDataComparison.mainData]
	);

	// * added comparison
	const handleClickFilter = useCallback(
		async (type, filterType = null) => {
			setSelectedFilter(type);
			setButtonFilterType(filterType);
			await Promise.all([
				updateChartArrData(chartData.filterPatients, filterType),
				updateChartArrData(chartDataComparison.filterPatients, filterType, undefined, undefined, true),
			]);
			if (selectedItem.length > 0) {
				await Promise.all([
					filterOptions(selectedItem, chartData.mainData, filterType),
					filterOptions(selectedItem, chartDataComparison.mainData, filterType, undefined, true),
				]);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			updateChartArrData,
			chartData.filterPatients,
			chartData.mainData,
			chartDataComparison.filterPatients,
			chartDataComparison.mainData,
			selectedItem,
		]
	);

	// * added comparison
	const handleChartLabelType = useCallback(
		async (type) => {
			setQueryFilters((prevState) => ({
				...prevState,
				chartPointType: type,
			}));
			setQueryFiltersComparison((prevState) => ({
				...prevState,
				chartPointType: type,
			}));
			await Promise.all([
				updateChartArrData(chartData.filterPatients),
				updateChartArrData(chartDataComparison?.filterPatients, undefined, undefined, undefined, true),
			]);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[chartData.filterPatients, chartDataComparison?.filterPatients]
	);

	// * added comparison
	const handleToggleAll = useCallback(
		async (value) => {
			if (value == "all") {
				const ids = filterData?.map((a) => a._id);
				const idsComparison = filterDataComparison?.map((x) => x._id);

				await Promise.all([
					filterOptions(ids, chartData.mainData, buttonFilterType),
					filterOptions(idsComparison, chartDataComparison.mainData, buttonFilterType, undefined, true),
				]);
				setSelectedItem([...ids]);
				setSelectedItemComparison([...idsComparison]);
			} else {
				setPatientData([]);
				setChartData((prevState) => ({
					...prevState,
					filterData: [],
					filterPatients: [],
				}));
				setSelectedItem([]);
				setChartDataComparison((prevState) => ({
					...prevState,
					filterData: [],
					filterPatients: [],
				}));
				setSelectedItemComparison([]);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[filterData, filterDataComparison, chartData.mainData, buttonFilterType, chartDataComparison.mainData]
	);

	const handleClose = useCallback(() => {
		dispatch(closeDetailsDialog());
	}, [dispatch]);

	const handleTabButton = useCallback((type) => {
		setSelectedTab(type);
	}, []);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const getFieldNames = (row) => {
		return getAdmissionFieldNames(row, filters?.relation, filters?.type);
	};

	const onClickReport = useCallback(
		(title, automaticallyReport) => {
			handleOnClickReport({
				selectedTab,
				filters,
				chartData: {
					...chartData,
					filterPatients: patientDataOrderBy(chartData?.filterPatients, order, orderBy)
				},
				selectedFilter,
				tableElementRef,
				buildingElementRef,
				title,
				queryFilters,
				selectedItem,
				filterData,
				automaticallyReport,
				buttonFilterType,
				orderBy,
				order,
				activeFacilitiesCount : activeFacilities?.length
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			selectedTab,
			filters,
			chartData,
			tableElementRef,
			buildingElementRef,
			queryFilters,
			selectedItem,
			filterData,
			buttonFilterType,
			orderBy,
			order,
			activeFacilities
		]
	);

	// * added comparison
	useEffect(() => {
		reset();
		if (filters && filters.filter) {
			const daysFilter = chartFilterPermission(filters.filter);
			const daysDiff = dayDifferent(filters.filter);

			setQueryFilters({
				filterButtons: daysFilter,
				days: daysDiff,
				startDate: filters.filter.startDate,
				endDate: filters.filter.endDate,
				chartPointType: CHART_POINT_TYPE.TOTAL,
			});
			getPatientChartsData();
		}
		if (filtersComparison && filtersComparison?.filter && rangesSet) {
			const daysFilter = chartFilterPermission(filtersComparison?.filter);
			const daysDiff = dayDifferent(filtersComparison?.filter);

			setQueryFiltersComparison({
				filterButtons: daysFilter,
				days: daysDiff,
				startDate: filtersComparison?.filter.startDate,
				endDate: filtersComparison?.filter.endDate,
				chartPointType: CHART_POINT_TYPE.TOTAL,
			});
			getPatientChartsData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters, filtersComparison, rangesSet]);

	const handleExcelReport = useCallback((selectedColumns = []) => {
		handleGenerateExcelReport({
			data: chartData?.filterPatients,
			filters,
			pageType: DASHBOARD_FILTER_TYPE.ADMISSION,
			getFieldNames,
			title,
			censusAverage,
			bedCapacity: dbData?.bedCapacity || 0,
			questions,
			selectedColumns,
			orderBy,
			order,
			activeFacilitiesCount : activeFacilities?.length
		});
	}, [chartData?.filterPatients, filters, getFieldNames, handleGenerateExcelReport, title, censusAverage, dbData, questions, orderBy, order, activeFacilities]);

	// below code use for search filter in table
	const [searchTerm, setSearchTerm] = useState('');
	const [totalHighlightedCount, setTotalHighlightedCount] = useState(0);
	const [debounceHighlightedCount, setDebounceHighlightedCount] = useState(0);
	const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
	const [highlightedCountDefaultValue, setHighlightedCountDefaultValue] = useState(0);
	const [selectedHighlightedColumns, setSelectedHighlightedColumns] = useState([]);

	// useEffect(() => {
	// 	if (questions && questions.length > 0) {
	// 		setSelectedHighlightedColumns([...questions?.map((ele) => ele?.question?.accessor), ...tableDefaultQuestions.map((ele) => ele?.question?.accessor), "selectAll"]);
	// 	}
	// }, [questions]);

	const [filterType, setFilterType] = useState({ type: '', filter: '', operation: '' });

	useEffect(() => {
		const handlerCount = _.debounce(() => setDebounceHighlightedCount(totalHighlightedCount), 200);
		handlerCount();
		return () => handlerCount.cancel();
	}, [totalHighlightedCount]);

	useEffect(() => {
		const handler = _.debounce(() => {
			setDebouncedSearchTerm(searchTerm);
			setFilterType({ type: typeof searchTerm, filter: searchTerm, operation: '' });
		}, 300);
		handler();
		return () => handler.cancel();
	}, [searchTerm]);

	const handleSearch = (event) => {
		const value = event.target.value;
		setSearchTerm(isNaN(value) || !value ? value : Number(value));
	};

	const handleHighlightedCount = useCallback((count) => {
		setTotalHighlightedCount(searchTerm ? count : 0);
	}, [searchTerm]);

	// end of below code use for search filter in table

	return (
		<ChartDialogContainer handleClose={handleClose} isOpen={isOpen}>
			<ChartDialogLeftSidebar
				loading={loading}
				filterData={filterData}
				selectedItem={selectedItem}
				handleToggle={handleToggle}
				handleToggleAll={handleToggleAll}
				title={title}
				selectedTab={selectedTab}
			/>
			<Grid item xs={10.7} style={{ padding: "30px" }}>
				<ChartDetailsTab
					selectedTab={selectedTab}
					handleTabButton={handleTabButton}
					queryFilters={queryFilters}
					handleOnClickReport={onClickReport}
					loading={loading}
					handleExcelReport={handleExcelReport}
					setSelectedFacility={setSelectedFacility}
					selectedFacility={selectedFacility}
					questions={questions}
					pageType={PAGE_TYPE.ADMISSION}
				/>
				{selectedTab === CHART_TAB_BUTTON.TABLE &&
					<ChartDetailsSearchBar
						searchTerm={searchTerm}
						handleSearch={handleSearch}
						setSearchTerm={setSearchTerm}
						highlightedCount={debounceHighlightedCount}
						filterType={filterType}
						setFilterType={setFilterType}
						selectedHighlightedColumns={selectedHighlightedColumns}
						setSelectedHighlightedColumns={setSelectedHighlightedColumns}
						selectedQuestions={questions}
					/>
				}
				{!loading && (
					<NoteContainer page={PAGE_TYPE.ADMISSION} openFrom="dialog">
						{selectedTab === CHART_TAB_BUTTON.BUILDING && (
							<Stack direction={"row"} sx={{ mt: 2, height: "99%" }}>
								<ChartBuildingList
									page={PAGE_TYPE.ADMISSION}
									data={chartData?.filterPatients || []}
									relation={filters?.relation}
									getFieldNames={getFieldNames}
									chartData={chartData}
									censusByFacility={censusByFacility}
									bedCapacityByFacility={bedCapacityByFacility}
									filterSelected={filters.filterSelected}
									filter={filters}
									buildingElementRef={buildingElementRef}
									dataComparison={chartDataComparison?.filterPatients || []}
									relationComparison={filtersComparison?.relation}
									chartDataComparison={chartDataComparison}
									censusByFacilityComparison={censusByFacilityComparison}
									bedCapacityByFacilityComparison={bedCapacityByFacilityComparison}
									filterSelectedComparison={filtersComparison?.filterSelected}
									filterComparison={filtersComparison}
									forAdmission={true}
									isTotalCard={filters?.isTotalCard}
									filterListData={filters?.filterListData}
									typeCard={filters?.type}
									isAdt={true}
									selectedADTTableData={filters?.selectedADTTableData}
								/>
							</Stack>
						)}
						{selectedTab === CHART_TAB_BUTTON.TABLE && (
							<Stack direction={"row"} sx={{ mt: 2, height: "99%" }}>
								<ChartTableList
									data={chartData.filterPatients}
									relation={filters?.relation}
									getFieldNames={getFieldNames}
									tableElementRef={tableElementRef}
									pageType={PAGE_TYPE.ADMISSION}
									questions={questions}
									searchTerm={debouncedSearchTerm}
									handleHighlightedCount={handleHighlightedCount}
									highlightedCountDefaultValue={highlightedCountDefaultValue}
									filterType={filterType}
									selectedHighlightedColumns={selectedHighlightedColumns}
									orderData={{
										orderBy,
										setOrderBy,
										order,
										setOrder
									}}
									activeFacilities={activeFacilities}
								/>
							</Stack>
						)}
						{selectedTab === CHART_TAB_BUTTON.CHART && (
							<Stack direction={"row"} sx={{ mt: 2, height: "90%" }}>
								<CardDetailChart
									isChartArrow={!filters?.cardFilter?.ADTFilter}
									totalText="Total Admissions"
									data={chartData.filterData}
									filters={filters}
									handleChartLabelType={handleChartLabelType}
									censusAverage={censusAverage}
									total={chartData.filterPatients.length || 0}
									setQueryFilters={setQueryFilters}
									queryFilters={queryFilters}
									handleClickFilter={handleClickFilter}
									selectedFilter={selectedFilter}
									reloadChartData={reloadChartData}
									isChartTotalButton={isShowPercentage}
									selectedFacility={selectedFacility}
									dbData={dbData}
								/>
							</Stack>
						)}
					</NoteContainer>
				)}
				<ChartTabLoader loading={loading} />
			</Grid>
		</ChartDialogContainer>
	);
}
