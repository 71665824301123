import { useSelector } from "react-redux";
import React from "react";
import { PAGE_TYPE } from "../../types/pages.type";

const useCardFilterAndTransferType = (pageType) => {
    const stateMapping = {
        [PAGE_TYPE.HOSPITAL]: useSelector((state) => state.hospital),
        [PAGE_TYPE.COMMUNITY_TRANSFER]: useSelector((state) => state.communityTransfer),
        [PAGE_TYPE.ADMISSION]: useSelector((state) => state.admission),
        [PAGE_TYPE.DECEASED]: useSelector((state) => state.deceased),
    };

    // Select the appropriate state based on pageType
    const selectedState = stateMapping[pageType];

    return React.useMemo(() => ({
        cardFilter: selectedState?.cardFilter,
        transferType: selectedState?.transferType,
    }), [selectedState]);
};

export default useCardFilterAndTransferType;
