import { Divider, List, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import { Fragment, useMemo } from "react";
import { OVERALL_PAGE_SUB_TYPE, PAGE_TYPE } from "../../../types/pages.type";
import { calcProPercentsBasedOnFilterAndDays, getPositivePlusSign, itemPercentage } from "../../../utilis/common";
import { calculateDiffBetweenPercentages, pickComparisonColor } from "../../../utilis/percentage-comparison";
import DoctorIcon from "../../icon/DoctorIcon";
import CardItemTooltip from "../../shared/CardItemTooltip";
import ColorBox from "../../shared/dashboard/ColorBox";
import TotalCountPercentage from "../totalCount/TotalCountPercentage";
import UserListSkeleton from "./UserListSkeleton";

const CustomeList = styled(List)(({ theme }) => ({
	color: theme.palette.typography.main,
}));

export const CustomeListItem = styled(ListItem)(({ theme }) => ({
	color: theme.palette.typography.main,
	height: 52,
	paddingLeft: "0px",
	"&.Mui-selected, .Mui-focusVisible": {
		backgroundColor: "#fff",
		border: "1px solid #4879F5",
		borderRadius: "10px",
		"&:before": {
			content: `""`,
			display: "block",
			height: "16px",
			width: "16px",
			backgroundColor: "#4879f5",
			position: "absolute",
			left: "-14px",
			transform: "translateX(-100%)",
			borderRadius: "50%",
			transition: "all 0.2s",
		},
	},
	"& .MuiListItemText-primary": {
		fontFamily: "manrope",
		fontWeight: 800,
		fontSize: 14,
		lineHeight: 19,
	},
	"& .MuiListItemButton-root": {
		"&:hover": {
			backgroundColor: "transparent",
		},
	},
	"& .MuiListItemSecondaryAction-root": {
		marginRight: 25,
		zIndex: 1,
		display: "flex",
		alignItems: "center",
		"& .MuiCheckbox-root": {
			position: "relative",
			marginRight: -30,
			marginLeft: 2,
		},
	},
}));

const UserList = ({
	data,
	comparisonData,
	filter,
	filterComparison,
	handleToggle,
	handleToggleAll,
	selectedItem = [],
	type,
	page,
	isPercentageShow,
	averageCensusComparison,
	averageCensus,
	admissionCompareAgainst,
	admissionCompareAgainstComparison,
	cardTitle,
	reverseColors,
	reverseColorsAdmissionPage,
	comparingAgainstDifferentFacility,
	loading,
	projectionDays,
	priorityNumber,
	transferType,
	isComparingAgainstAvgCensus,
	lockedTotalBy,
	searchValue,
}) => {
	const specialPages = [PAGE_TYPE.ADMISSION];
	const isSpecialPage = specialPages.find((x) => x === page)?.length > 0;
	const isFirstItemInPriorityData = priorityNumber === 1;
	const isPlusSign = useMemo(() => {
		return page === PAGE_TYPE.OVERALL && transferType === OVERALL_PAGE_SUB_TYPE.TOTAL;
	}, [page, transferType]);

	return (
		<CustomeList>
			{!loading ? (
				data
					?.filter((x) => x.label.toLowerCase().includes(searchValue.toLowerCase()))
					.map((item) => {
						const id = item._id;
						const { total = 0 } = item;
						let comparisonColor;
						let comparingAgainstScaled;
						let numberOfDays;
						const itemComparison = _.find(comparisonData, { _id: item._id });
						const percentageOfAverageCensus = itemPercentage(total, averageCensus, "number");
						const percentageOfAverageCensusComparison = itemPercentage(
							itemComparison?.total || 0,
							averageCensusComparison,
							"number"
						);

						if (page === PAGE_TYPE.ADMISSION) {
							const totalParsed = itemPercentage(total, admissionCompareAgainst, "number");
							const totalComparisonParsed = itemPercentage(
								itemComparison?.total,
								admissionCompareAgainstComparison,
								"number"
							);

							const {
								percentageDiff,
								itemTotalComparisonScaled,
								numberOfDays: numberOfDaysTemp,
							} = calculateDiffBetweenPercentages(
								totalParsed,
								totalComparisonParsed,
								reverseColorsAdmissionPage,
								filter,
								filterComparison,
								itemComparison?.total,
								projectionDays
							);

							numberOfDays = numberOfDaysTemp;
							comparingAgainstScaled = itemTotalComparisonScaled;
							comparisonColor = pickComparisonColor(percentageDiff, isSpecialPage);
						} else {
							const {
								percentageDiff,
								itemTotalComparisonScaled,
								numberOfDays: numberOfDaysTemp,
							} = calculateDiffBetweenPercentages(
								percentageOfAverageCensus,
								percentageOfAverageCensusComparison,
								reverseColors,
								filter,
								filterComparison,
								itemComparison?.total,
								projectionDays
							);

							numberOfDays = numberOfDaysTemp;
							comparingAgainstScaled = itemTotalComparisonScaled;
							comparisonColor = pickComparisonColor(percentageDiff, isSpecialPage);
						}

						if (comparingAgainstDifferentFacility) {
							comparisonColor = null;
						}
						
						return (
							<Fragment key={id + "li-list-dashboard"}>
								<CustomeListItem
									selected={selectedItem.indexOf(id) !== -1}
									secondaryAction={
										<Fragment>
											{!loading && (
												<ColorBox
													type="list"
													color={comparisonColor}
													comparingAgainst={itemComparison?.total}
													comparingAgainstScaled={isPlusSign ? getPositivePlusSign(comparingAgainstScaled) : comparingAgainstScaled}
													numberOfDays={numberOfDays}
													filter={filter}
												/>
											)}
											<TotalCountPercentage
												value={calcProPercentsBasedOnFilterAndDays(total, { ...filter, transferType }, projectionDays)}
												isPercentageShow={isPercentageShow}
												percentage={`(${calcProPercentsBasedOnFilterAndDays(
													item.percentage,
													{ ...filter, transferType },
													projectionDays,
													true,
													isComparingAgainstAvgCensus ||
													lockedTotalBy === "census" ||
													(isFirstItemInPriorityData && (!transferType || transferType.length === 0))
												)}%)`}
											/>
											<Checkbox
												disableRipple
												edge="end"
												onChange={(e) => {
													handleToggle({
														item,
														type,
														itemComparison,
														isChecked: selectedItem.indexOf(id) !== -1,
														cardTitle,
													});
												}}
												checked={selectedItem.indexOf(id) !== -1}
											/>
										</Fragment>
									}
								>
									<ListItemButton disableRipple sx={{ width: "80%" }}>
										<ListItemAvatar sx={{ height: "36px" }}>
											<DoctorIcon />
										</ListItemAvatar>
										<ListItemText
											primary={
												<CardItemTooltip item={item}>
													<Typography variant="subtitle1" sx={{ maxWidth: "fit-content" }}>
														{item.label || ""}
													</Typography>
												</CardItemTooltip>
											}
										/>
									</ListItemButton>
								</CustomeListItem>
								<Divider variant="middle" component="li" />
							</Fragment>
						);
					})
			) : (
				<UserListSkeleton />
			)}
			{data?.length === 0 && !loading && (
				<Typography variant="subtitle2" align={"center"}>
					No data found
				</Typography>
			)}
		</CustomeList>
	);
};

export default UserList;
