
//* eslint-disable react-hooks/exhaustive-deps */
import { LoadingButton } from "@mui/lab";
import Cleave from "cleave.js/react";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { BackArrowSVG } from "../../../../../assets/svgs/back-arrow.svg";
import CloseIcon from "../../../../../assets/svgs/close.svg";
import ExpandPopupSVG from "../../../../../assets/svgs/expand-popup.svg";
import NewCloseSVG from "../../../../../assets/svgs/new-close.svg";
import axios from "../../../../../axios";
import { checkAdmissionPriorData, checkHospitalPriorData } from "../../../../../services/api/admission.api";
import { getCensusFirstDate } from "../../../../../services/data-input.service";
import { ADD_NOTIFICATION } from "../../../../../store/types";
import { ADT_SUB_TYPES, ADT_TYPES, ADT_VALIDATION_TYPES } from "../../../../../types/common.type";
import { isOnlyHospitalTabAccess, updateOrAddToArray } from "../../../../../utilis/common";
import { toDisplayTime, toSaveDate } from "../../../../../utilis/date-formats";
import styles from "./AddPatient.module.scss";
import AdmissionOrReadmissionSelect from "./AdmissionOrReadmissionSelect";
import BasicInfo from "./BasicInfo";
import MissingADT from "./MissingADT";
import Questions from "./Questions";
import SelectType from "./SelectType";
import ShowDuplicateADT from "./ShowDuplicateADT";
import ShowPriorHospitalInput from "./ShowPriorHospitalInput";
import ValidationADT from "./ValidationADT";
import AddQuestionHighlighter from "./AddQuestionHighlighter";
import { getSameDateTransfer } from "../../../../../services/adt.service";
import { useConfirm } from "../../../../shared/dialogs";
import SelectTypeNew from "./SelectTypeNew";
import { Box, CircularProgress } from "@mui/material";
import AlertDialog from "../../../../shared/AlertDialog";


const AddPatient = props => {
    const dispatch = useDispatch();
    const [step, setStep] = useState(props.step || 2);
    const [showAddLatestAdmission, setShowAddLatestAdmission] = useState(false);
    const [latestAdmission, setLatestAdmission] = useState(undefined);
    const [showDuplicateADT, setShowDuplicateADT] = useState(false);
    const [showAddMissingADT, setShowAddMissingADT] = useState(false);
    const [validation, setValidation] = useState(null);
    const [questions, setQuestions] = useState([]);
    const [formSubmitted, setFormSubmitted] = useState(null);
    const [mostRecentADT, setMostRecentADT] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isAskAdmissionDate, setIsAskAdmissionDate] = useState(false);
    const [isAddOrReaddDialog, setIsAddOrReaddDialog] = useState(false);
    const [isAdmissionSelected, setIsAdmissionSelected] = useState(false);
    const [DBadmissionType, setDBadmissionType] = useState(null);
    const [censusDate, setCensusDate] = useState(null);
    const [isPriorHospitalization, setIsPriorHospitalization] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isPriorDateOpened, setIsPriorDateOpened] = useState(false);
    const [mostRecentLoading, setMostRecentLoading] = useState(false);
    const [validationInfo, setValidationInfo] = useState(null);
    const [isValidationInfo, setIsValidationInfo] = useState(false);

    const isOnlyHospitalDashboard = useMemo(() => {
        return isOnlyHospitalTabAccess();
    }, []);

    const debounceTime = 300; // Debounce time in milliseconds
    const [allFields, setAllFields] = useState({});
    const prevFieldsRef = useRef({
        firstName: "",
        lastName: "",
        DOB: "",
    });

    useMemo(() => {
        if (props.selectedResident) {
            let validationType;
            if (!isOnlyHospitalDashboard) {
                const { type } = props.selectedResident;
                if (
                    type === ADT_TYPES.ADMISSIONS ||
                    type === ADT_TYPES.READMISSIONS ||
                    type === ADT_TYPES.RETURN
                ) {
                    validationType = ADT_VALIDATION_TYPES.INCOMING;
                } else if (type === ADT_TYPES.TRANSFER) {
                    setIsAskAdmissionDate(props.selectedResident?.dateOfLatestAdmission ? true : false);
                    validationType = ADT_VALIDATION_TYPES.OUTGOING;
                }
            } else {
                validationType = ADT_VALIDATION_TYPES.ALL
            }
            const resData = Object.assign(props.selectedResident, {
                DOB: toDisplayTime(props.selectedResident?.DOB),
                dateOfADT: toDisplayTime(props.selectedResident?.dateOfADT),
                dateOfLatestAdmission: props.selectedResident?.dateOfLatestAdmission
                    ? toDisplayTime(props.selectedResident?.dateOfLatestAdmission)
                    : null,
                validationType
            });
            prevFieldsRef.current = { firstName: resData.firstName, lastName: resData.lastName, DOB: resData.DOB };
            setAllFields(resData);
        } else {
            return {}
        }
    }, [isOnlyHospitalDashboard, props?.selectedResident]);

    const [isReadmissionValid, setIsReadmissionValid] = useState(false);
    const [isHighLightInfo, setIsHighLightInfo] = useState({ isOpen: false, selectedValue: null });

    useEffect(() => {
        if (props.selectedResident) {
            setSelectedItem(props.selectedResident);
            //setAllFields({ ...updatedValues });
        } else {
            setSelectedItem(null);
        }
    }, [props?.selectedResident]);

    useEffect(() => {
        const fetchCensusDate = async (facilityId) => {
            const censusData = await getCensusFirstDate(facilityId);
            setCensusDate(censusData.date);
        };
        const facilityIds = props?.patientId && props?.isMultipleFacility ? props?.selectedResident?.facilityId?._id : localStorage.getItem("facilityId")
        fetchCensusDate(facilityIds);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.patientId]);


    const checkQuestionsValidation = useCallback(() => {
        let errors = [];
        // if (allFields?.type === "transfer" && allFields?.transferType === "hospitalTransfer") {
        //     errors.push(`Please select transfer type`);
        // }
        if (questions && questions.length > 0) {
            questions.map(item => {
                const fieldName = item.question?.accessor || null;
                const type = item.question?.type || null;
                const dependsOn = item.question?.dependsOn || null;
                const dependsOnValues = item.question?.dependsOnValues || [];
                const isRequired = item.question?.isRequired || false;
                if (fieldName && isRequired) {
                    if (type === "toggle") {
                        if (allFields?.type === "transfer" && allFields?.transferType === "hospitalTransfer") {
                            errors.push(`Please select ${item.question.label}`);
                        }
                        if (allFields[fieldName] === undefined || allFields[fieldName] == null) {
                            errors.push(`Please select ${item.question.label}`);
                        }
                    } else if (type === "date") {
                        if (dependsOn === "server") {
                            let isCheckValid;
                            if (selectedItem) {
                                isCheckValid = isAskAdmissionDate || allFields[fieldName] != null ? true : false;
                            } else {
                                isCheckValid = isAskAdmissionDate ? true : false;
                            }
                            if (isCheckValid && !allFields[fieldName]) {
                                errors.push(`Please select ${item.question.label}`);
                            }
                            if (
                                allFields[fieldName] &&
                                !moment(allFields[fieldName], "MM/DD/YYYY", true).isValid()
                            ) {
                                errors.push("Please enter Valid Date");
                            }

                            if (
                                allFields[fieldName] &&
                                moment(allFields[fieldName], "MM/DD/YYYY", true).isValid() &&
                                moment(allFields[fieldName], "MM/DD/YYYY").isAfter(moment())
                            ) {
                                errors.push(`${item.question.label} cannot be in the future date!`);
                            }
                        } else {
                            if (!allFields[fieldName]) {
                                errors.push(`Please select ${item.question.label}`);
                            }
                            if (moment(toSaveDate(allFields[fieldName])).isBefore(censusDate)) {
                                errors.push(`Please enter Date After Census Initial Date.`);
                            }
                            if (
                                allFields[fieldName] &&
                                !moment(allFields[fieldName], "MM/DD/YYYY", true).isValid()
                            ) {
                                errors.push("Please enter Valid Date");
                            }
                            if (
                                allFields[fieldName] &&
                                moment(allFields[fieldName], "MM/DD/YYYY", true).isValid() &&
                                moment(allFields[fieldName], "MM/DD/YYYY").isAfter(moment())
                            ) {
                                errors.push(`${item.question.label} cannot be in the future date!`);
                            }
                            if (allFields.type === "transfer" && allFields["dateOfLatestAdmission"] && allFields["DOB"]) {
                                if (moment(allFields["dateOfLatestAdmission"], "MM/DD/YYYY", true).isSameOrBefore(moment(allFields["DOB"], "MM/DD/YYYY", true))) {
                                    setIsReadmissionValid(true);
                                    errors.push("Please enter Date of latest Admission / Readmission After DOB Date.");
                                }
                            }
                            if (allFields.type === "transfer" && allFields["dateOfLatestAdmission"] && allFields["dateOfADT"]) {
                                if (moment(allFields["dateOfLatestAdmission"], "MM/DD/YYYY", true).isAfter(moment(allFields["dateOfADT"], "MM/DD/YYYY", true))) {
                                    setIsReadmissionValid(true);
                                    errors.push("Please enter Date of latest Admission / Readmission before or same Date of Transfer.");
                                }
                            }
                        }
                    } else if (dependsOn) {
                        const dependFieldValue = allFields[dependsOn];
                        if (dependsOnValues.length > 0) {
                            // eslint-disable-next-line array-callback-return
                            dependsOnValues.map(ele => {
                                if (ele === dependFieldValue && !allFields[fieldName]) {
                                    errors.push(`Please select ${item.question.label}`);
                                }
                            });
                        }
                    } else if (!allFields[fieldName]) {
                        errors.push(`Please select ${item.question.label}`);
                    }
                }
                return false;
            });
        }

        if (errors.length > 0) {
            setFormSubmitted("questions");
            setValidation({ type: "questions", errors });
            return false;
        } else {
            setFormSubmitted(null);
            setValidation(null);
            return true;
        }
    }, [allFields, questions, selectedItem, isAskAdmissionDate, censusDate]);

    const getInitialDate = async (facilityIdData) => {
        return await getCensusFirstDate(facilityIdData).then((res) => {
            if (res) {
                return res?.date
            }
        });
    }

    const checkSameDateOfADTForTransfer = async (allFields) => {
        const facilityId = props?.patientId && props?.selectedResident?.facilityId?._id ? props?.selectedResident?.facilityId?._id : localStorage.getItem("facilityId")
        if (facilityId) {
            let sameDateADTBody = {
                facilityId: facilityId,
                firstName: allFields.firstName,
                lastName: allFields.lastName,
                DOB: toSaveDate(allFields.DOB),
                dateOfADT: toSaveDate(allFields.dateOfADT),
                type: allFields._id ? "edit" : "add",
                id: allFields?._id || null,
                ADTtype: allFields?.type || null,
            }
            const res = await getSameDateTransfer(sameDateADTBody);
            return res;
        }
        return null
    };

    const deleteConfirm = useConfirm();

    const add = async skipClose => {
        const isCensusDateCheck = isOnlyHospitalDashboard ? true : censusDate !== null;
        if (checkQuestionsValidation() && isCensusDateCheck && allFields.dateOfADT) {
            setLoading(true);
            if (isOnlyHospitalDashboard) {
                const res = await checkSameDateOfADTForTransfer(allFields);
                let isSameDate = false;
                if (res) {
                    const text = `A prior hospital transfer was input for ${allFields?.firstName} ${allFields?.lastName} on ${allFields?.dateOfADT} <br>
                Are you sure you want to input an addition transfer on the same day ?`;
                    await deleteConfirm(text).then(() => {
                        isSameDate = false;
                    }).catch(() => {
                        isSameDate = true;
                    });
                }
                if (isSameDate) {
                    setLoading(false);
                    return true;
                }
            }

            const facilityIds = props?.patientId && props?.isMultipleFacility ? props?.selectedResident?.facilityId?._id : localStorage.getItem("facilityId")
            const initialDates = props?.patientId ? await getInitialDate(facilityIds) : props.initialDate
            let formSelectedType = null;
            const admissionPriorData = await checkAdmissionPriorData({
                facilityId: facilityIds,
                firstName: allFields.firstName,
                lastName: allFields.lastName,
                DOB: toSaveDate(allFields.DOB),
                dateOfADT: toSaveDate(allFields.dateOfADT),
                id: allFields?._id || null,
            });
            const resAdmissionPriorData = admissionPriorData?.data || null;
            const totalData = admissionPriorData?.total || 0;

            if ((allFields?.type === ADT_TYPES.ADMISSIONS && !isAdmissionSelected) || (totalData === 1 && !isAdmissionSelected && allFields?.type !== ADT_TYPES.TRANSFER && allFields?.type !== ADT_TYPES.RETURN)) {
                // !allFields.hasOwnProperty("isHospitalPrior")
                if (!resAdmissionPriorData) {
                    setIsAdmissionSelected(true);
                    setIsAddOrReaddDialog(true);
                    setLoading(false);
                    return false;
                } else if (resAdmissionPriorData) {
                    if (props?.patientId === resAdmissionPriorData?._id) {
                        setIsAdmissionSelected(true);
                        setIsAddOrReaddDialog(true);
                        setLoading(false);
                        return false;
                    }
                    const dateOfADTDate = allFields.dateOfADT ? moment(allFields.dateOfADT) : moment();
                    const priorDateOfADTDate = resAdmissionPriorData?.dateOfADT ? moment(resAdmissionPriorData.dateOfADT) : null;

                    const diffMonth = dateOfADTDate && priorDateOfADTDate
                        ? dateOfADTDate.diff(priorDateOfADTDate, "months")
                        : 0;
                    if (diffMonth <= 6) {
                        if (resAdmissionPriorData && resAdmissionPriorData.type === ADT_TYPES.TRANSFER && (
                            resAdmissionPriorData.transferType === ADT_SUB_TYPES.SAFE_DISCHARGE ||
                            resAdmissionPriorData.transferType === ADT_SUB_TYPES.SNF ||
                            resAdmissionPriorData.transferType === ADT_SUB_TYPES.AMA
                        )) {
                            formSelectedType = ADT_TYPES.ADMISSIONS;
                        } else {
                            formSelectedType = ADT_TYPES.READMISSIONS;
                        }
                    } else {
                        formSelectedType = ADT_TYPES.ADMISSIONS;
                    }
                }
            }

            if (allFields.type === ADT_TYPES.TRANSFER && (allFields.transferType === ADT_SUB_TYPES.PLANNED_HOSPITAL_TRANSFER || allFields.transferType === ADT_SUB_TYPES.UNPLANNED_HOSPITAL_TRANSFER)) {
                const after30DaysDay = moment(initialDates).add(30, 'days').toDate();
                const isBetweenDate = moment(allFields.dateOfADT, "MM/DD/YYYY").isBetween(props.initialDate, after30DaysDay);

                if (facilityIds) {
                    const resHospitalPrior = await checkHospitalPriorData({
                        facilityId: facilityIds,
                        firstName: allFields.firstName,
                        lastName: allFields.lastName,
                        DOB: toSaveDate(allFields.DOB),
                        dateOfADT: toSaveDate(allFields.dateOfADT),
                        id: allFields?._id || null,
                        type: allFields?.type || null,
                    });
                    if (isBetweenDate && !resHospitalPrior && !isPriorDateOpened) {
                        setIsPriorDateOpened(true);
                        setLoading(false)
                        setIsPriorHospitalization(true);
                        return false;
                    }
                } else {
                    dispatch({
                        type: ADD_NOTIFICATION,
                        payload: {
                            label: "Please select facility, or refresh page and try again later.",
                            type: "error",
                            id: "facilitySelectionError",
                        },
                    });
                }
            }
            setIsPriorHospitalization(false);
            setIsAdmissionSelected(false);
            if (formSelectedType) {
                allFields.type = formSelectedType;
            }
            // return false;
            if (allFields.DOB) {
                allFields.DOB = toSaveDate(allFields.DOB);
            }
            if (allFields.dateOfADT) {
                allFields.dateOfADT = toSaveDate(allFields.dateOfADT);
            }
            if (allFields.dateOfLatestAdmission) {

                allFields.dateOfLatestAdmission = allFields.dateOfLatestAdmission
                    ? toSaveDate(allFields.dateOfLatestAdmission)
                    : null;
            }
            if (allFields.firstName) {
                allFields.firstName = allFields.firstName.trim();
            }
            if (allFields.lastName) {
                allFields.lastName = allFields.lastName.trim();
            }
            // console.log(allFields, 'allFields');
            // return false;
            let d = await axios.post(`/api/patient${props.patientId ? `/${props.patientId}` : ""}`, {
                ...allFields,
                ...!props?.patientId && { facilityId: localStorage.getItem("facilityId") },
            });
            if (!props.patientId) {
                props.patientAdded(d.data);
            } else {
                props.patientUpdated(d.data);
            }
            setLoading(false)
            !skipClose && props.close();
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "success",
                    label: `Patient ${props.patientId ? "updated" : "added"} successfully`,
                    id: "patientAddedUpdated",
                },
            });
        }
    };

    const setAllFieldsValue = useCallback(async (field, value) => {

        if (value && Array.isArray(value)) {
            const updatedArrayValue = value.filter(item => item !== undefined);
            if (updatedArrayValue && updatedArrayValue.length > 0) {
                setAllFields((prevState) => ({
                    ...prevState,
                    [field]: updatedArrayValue,
                }));
            }
        } else if (typeof value == "boolean") {

            setAllFields((prevState) => ({
                ...prevState,
                [field]: value,
            }))
        } else if (value !== undefined) {

            setAllFields((prevState) => ({
                ...prevState,
                [field]: value,
            }))
        }
    }, [setAllFields]);

    const gotToDetails = () => {
        const { type, transferType } = allFields;
        if (!type) {
            setValidation({ type: "ADT", errors: ["Please select at least one ADT Type"] });
        } else if (type === "transfer" && !transferType) {
            setValidation({ type: "ADT", errors: ["Please select sub type of ADT"] });
        } else {
            setStep(2);
        }
    };

    const addLatestAdmission = async () => {
        await axios.post(`/api/patient`, {
            ...allFields,
            type: "admission",
            dateOfADT: latestAdmission,
            facilityId: localStorage.getItem("facilityId"),
        });
        setShowAddLatestAdmission(true);
        setStep(3);

        dispatch({
            type: ADD_NOTIFICATION,
            payload: {
                type: "success",
                label: "Admission added successfully",
                id: "admissionAdded",
            },
        });
    };

    useEffect(() => {
        if (allFields.transferTo && allFields.transferTo !== "assistedLiving") {
            // setShowAddLatestAdmission(true);
            setAllFieldsValue("transferToWhichAssistedLiving", null);
        }
        // eslint-disable-next-line
    }, [allFields.transferTo]);

    const ifDuplicateADT = (newType, previousType) => {
        if (selectedItem && selectedItem.type === allFields.type) {
            return false
        } else if (
            (["admission", "readmission", "return", "admissionOrReadmission"].includes(newType) &&
                ["admission", "readmission", "return", "admissionOrReadmission"].includes(previousType)) ||
            newType === previousType
        ) {
            return true;
        } else {
            return false;
        }
    };
    const resetValiDation = useCallback(() => {
        setValidation(null);
    }, []);

    const isDOBValidation = useCallback(() => {
        if (allFields.DOB && moment(allFields.DOB, "MM/DD/YYYY", true).isValid() && moment().diff(moment(allFields.DOB, "MM/DD/YYYY"), 'year') <= 18) {
            return true
        }
        return false;
    }, [allFields?.DOB]);

    const checkBasicInfoValidation = useCallback(() => {
        let errors = [];
        const { type, transferType, } = allFields;
        if (!type) {
            errors.push("Please select ADT Type");
        }
        if (type === "transfer" && !transferType) {
            errors.push("Please select sub type of ADT");
        }
        if (!allFields.lastName) {
            errors.push("Please enter Last Na‍me");
        }
        if (!allFields.firstName) {
            errors.push("Please enter First Name");
        }

        if (!allFields.DOB) {
            errors.push("Please enter DOB");
        }

        if (allFields.DOB && !moment(allFields.DOB, "MM/DD/YYYY", true).isValid()) {
            errors.push("Please enter Valid DOB");
        }

        if (errors.length > 0) {
            setValidation({ type: "basicInfo", errors });
            setFormSubmitted("basicInfo");
            return true;
        } else {
            setFormSubmitted(null);
            resetValiDation();
            return false;
        }
    }, [allFields, resetValiDation]);

    const checkIsError = useCallback(
        field => {
            if (formSubmitted && formSubmitted === field) {
                return true;
            } else {
                return false;
            }
        },
        [formSubmitted]
    );

    const handleMostRecentADT = useCallback(() => {
        if (isDOBValidation() && !isValidationInfo) {
            setValidationInfo({
                message: "You are entering a resident who is 18 years old or younger. Are you sure the DOB is correct?",
                isOpen: true
            });
        } else {
            if (!isOnlyHospitalDashboard) {
                if (!mostRecentADT && allFields.type === "transfer") {
                    console.log(222222);
                    if (allFields._id) {
                        if (allFields.dateOfLatestAdmission) {
                            setIsAskAdmissionDate(true);
                        } else {
                            setIsAskAdmissionDate(false);
                        }
                    } else {
                        setIsAskAdmissionDate(true);
                    }
                }
                if (mostRecentADT && mostRecentADT.admissionType === "admission") {
                    setAllFields(prevState => ({
                        ...prevState,
                        "dx": mostRecentADT?.dxData || []
                    }));
                }
            }
            console.log(step, 'step');
            setStep(step + 1);
        }
    }, [isDOBValidation, isValidationInfo, isOnlyHospitalDashboard, step, mostRecentADT, allFields]);

    // eslint-disable-next-line no-unused-vars
    const handleMostRecentADTOld = useCallback(
        async () => {
            if (!isOnlyHospitalDashboard) {
                setLoading(true)
                const facilityId = props?.patientId && props?.selectedResident?.facilityId?._id ? props?.selectedResident?.facilityId?._id : localStorage.getItem("facilityId")
                if (allFields.type === ADT_TYPES.ADMISSIONS || allFields.type === ADT_TYPES.READMISSIONS) {
                    setIsAddOrReaddDialog(true);
                }
                if (facilityId) {
                    let localMostRecentADT = await axios.post(`/api/patient/most-recent-adt/`, {
                        facilityId: facilityId,
                        firstName: allFields.firstName,
                        lastName: allFields.lastName,
                        DOB: toSaveDate(allFields.DOB),
                        type: allFields._id ? "edit" : "add",
                        id: allFields?._id || null,
                        ADTtype: allFields?.type || null,
                    });

                    setDBadmissionType(localMostRecentADT?.data?.admissionType || "");

                    if (localMostRecentADT && localMostRecentADT.data && localMostRecentADT.data.data) {
                        const mostRecentADTPatient = localMostRecentADT.data.data;
                        const dxDataAddOrRe = localMostRecentADT.data.dxData || [];
                        setIsAskAdmissionDate(false);
                        setMostRecentADT(mostRecentADTPatient);
                        if (ifDuplicateADT(mostRecentADTPatient?.type, allFields.type)) {
                            setShowDuplicateADT(true);
                        } else {
                            // if (!selectedItem && allFields.type === "admissionOrReadmission") {
                            if (allFields.type === "admissionOrReadmission") {
                                if (localMostRecentADT.data.admissionType) {
                                    if (selectedItem && selectedItem.type !== allFields.type) {
                                        await setIsAddOrReaddDialog(true);
                                        setAllFields(prevState => ({
                                            ...prevState,
                                            "type": localMostRecentADT.data.admissionType
                                        }));
                                    } else {
                                        setAllFields(prevState => ({
                                            ...prevState,
                                            "type": localMostRecentADT.data.admissionType
                                        }));
                                    }
                                } else {
                                    await setIsAddOrReaddDialog(true);
                                }
                            }
                            setStep(step + 1);
                            if (localMostRecentADT.data.admissionType === "admission") {
                                setAllFields(prevState => ({
                                    ...prevState,
                                    "dx": dxDataAddOrRe
                                }));
                            }
                        }
                    } else {
                        setMostRecentADT(null);
                        if (allFields.type === "transfer") {
                            setIsAskAdmissionDate(true);
                        }
                        if (!localMostRecentADT.data.admissionType && allFields.type === "admissionOrReadmission") {
                            setIsAddOrReaddDialog(true);
                        }
                        setStep(step + 1);
                    }
                    setLoading(false)
                } else {
                    dispatch({
                        type: ADD_NOTIFICATION,
                        payload: {
                            label: "Please select facility, or refresh page and try again.",
                            type: "error",
                            id: "facilitySelectionError",
                        },
                    });
                    setLoading(false)
                }
            } else {
                // setAllFields({ ...updatedValues });
                // if (!_.isEmpty(updatedValues)) {
                // }              
                setStep(step + 1);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [allFields, selectedItem, step, isOnlyHospitalDashboard, props]
    );

    const handleAddHighlight = useCallback((value) => {
        let selectedValue = allFields?.highlighter?.find(item => item.fieldName === value) || null;
        setIsHighLightInfo({ isOpen: true, selectedValue: selectedValue ? { ...selectedValue, isEdit: true } : { fieldName: value, color: '', description: '' } });
    }, [allFields]);

    const handleSaveHighlight = useCallback(async (value) => {
        delete value?.isEdit;
        const updatedValues = await updateOrAddToArray(allFields.highlighter, value, "fieldName");
        setAllFields((prevState) => ({
            ...prevState,
            highlighter: updatedValues,
        }));
        setIsHighLightInfo({ isOpen: false, selectedValue: null });
    }, [allFields]);

    const handleDeleteHighlight = useCallback(async (value) => {
        const updatedHighlighter = allFields?.highlighter?.filter(item => item?.fieldName !== value?.fieldName) || [];
        setAllFields((prevState) => ({
            ...prevState,
            highlighter: updatedHighlighter,
        }));
        setIsHighLightInfo({ isOpen: false, selectedValue: null });
    }, [allFields]);

    const handleValidate = useCallback(async () => {
        if (!isOnlyHospitalDashboard) {
            setLoading(true);
            setMostRecentLoading(true);
            const facilityId = props?.patientId && props?.selectedResident?.facilityId?._id ? props?.selectedResident?.facilityId?._id : localStorage.getItem("facilityId")

            if (facilityId) {
                let localMostRecentADT = await axios.post(`/api/patient/most-recent-adt/`, {
                    facilityId: facilityId,
                    firstName: allFields.firstName,
                    lastName: allFields.lastName,
                    DOB: toSaveDate(allFields.DOB),
                    type: allFields._id ? "edit" : "add",
                    id: allFields?._id || null,
                    ADTtype: allFields?.type || null,
                });

                const priorData = localMostRecentADT?.data || null;

                if (!priorData) {
                    setMostRecentLoading(false);
                    setLoading(false);
                    setMostRecentADT(null);
                    setAllFields(prevState => ({
                        ...prevState,
                        type: null,
                        "validationType": ADT_VALIDATION_TYPES.ALL
                    }));
                    return false;
                }

                const { priorType, priorTransferType, data } = priorData;
                console.log(data, 'data D');

                setMostRecentADT(data);
                setIsAskAdmissionDate(false);

                if (
                    priorType === ADT_TYPES.ADMISSIONS ||
                    priorType === ADT_TYPES.READMISSIONS ||
                    priorType === ADT_TYPES.RETURN
                ) {
                    console.log(2.1);
                    setAllFields(prevState => ({
                        ...prevState,
                        type: null,
                        "validationType": ADT_VALIDATION_TYPES.OUTGOING
                    }));
                } else if (priorType === ADT_TYPES.TRANSFER && priorTransferType === ADT_SUB_TYPES.DECEASED) {
                    console.log(2.2);
                    setAllFields(prevState => ({
                        ...prevState,
                        type: null,
                        validationType: ADT_VALIDATION_TYPES.DECEASED
                    }));
                } else if (priorType === ADT_TYPES.TRANSFER && (priorTransferType === ADT_SUB_TYPES.SAFE_DISCHARGE ||
                    priorTransferType === ADT_SUB_TYPES.SNF ||
                    priorTransferType === ADT_SUB_TYPES.AMA)) {
                    console.log(2.3);
                    setAllFields(prevState => ({
                        ...prevState,
                        type: priorData.admissionType ?? null,
                        validationType: ADT_VALIDATION_TYPES.ADMISSIONS
                    }));
                } else if (priorType === ADT_TYPES.TRANSFER) {
                    console.log(2.2);
                    console.log(data?.wasAdmitted, 'data?.wasAdmitted');
                    // const dateOfADTDate = data.dateOfADT ? data.dateOfADT : moment().toDate();
                    // const todayDate = moment().startOf("day");
                    // const diffMonth = todayDate.diff(dateOfADTDate, "months");
                    // DC : data?.wasAdmitted === true
                    // ER : data?.wasAdmitted === false
                    console.log(1);
                    console.log(data, 'data DC ER');

                    if (!data?.wasAdmitted) {
                        console.log(1.1);
                        setAllFields(prevState => ({
                            ...prevState,
                            validationType: ADT_VALIDATION_TYPES.RETURN
                        }));
                    } else {
                        console.log(1.2);
                        setAllFields(prevState => ({
                            ...prevState,
                            validationType: ADT_VALIDATION_TYPES.ADMISSIONS,
                        }));
                    }
                } else {
                    setAllFields(prevState => ({
                        ...prevState,
                        type: null,
                        "validationType": ADT_VALIDATION_TYPES.ALL
                    }));
                }
                setMostRecentLoading(false);
                setLoading(false);
            } else {
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        label: "Please select facility, or refresh page and try again.",
                        type: "error",
                        id: "facilitySelectionError",
                    },
                });
                setMostRecentLoading(false);
                setLoading(false)
            }
        } else {
            setAllFields(prevState => ({
                ...prevState,
                "validationType": ADT_VALIDATION_TYPES.ALL
            }));
        }
    }, [allFields.DOB, allFields._id, allFields.firstName, allFields.lastName, allFields?.type, dispatch, isOnlyHospitalDashboard, props?.patientId, props?.selectedResident?.facilityId?._id, step]); // eslint-disable-line react-hooks/exhaustive-deps



    useEffect(() => {
        const { firstName, lastName, DOB } = allFields;
        const { firstName: prevFirstName, lastName: prevLastName, DOB: prevDOB } = prevFieldsRef.current;
        // Check if firstName, lastName, or DOB has changed
        if (
            (firstName !== prevFirstName || lastName !== prevLastName || DOB !== prevDOB) &&
            firstName &&
            lastName &&
            DOB &&
            moment(DOB, "MM/DD/YYYY", true).isValid()
        ) {
            // Update the ref with the new values
            prevFieldsRef.current = { firstName, lastName, DOB };

            // Debounced API call
            const handler = setTimeout(() => {
                // Call the API here
                setMostRecentADT(null);
                setAllFields(prevState => ({
                    ...prevState,
                    type: "",
                    transferType: "",
                    validationType: "",
                }));
                handleValidate();
            }, debounceTime);
            // Cleanup the timeout if the dependencies change before the timeout is complete
            return () => clearTimeout(handler);
        } else if (!firstName || !lastName || !DOB || !moment(DOB, "MM/DD/YYYY", true).isValid()) {
            setAllFields(prevState => ({
                ...prevState,
                type: "",
                transferType: "",
                validationType: "",
            }));
        }
    }, [allFields?.firstName, allFields?.lastName, allFields?.DOB, handleValidate, isOnlyHospitalDashboard]); // eslint-disable-line react-hooks/exhaustive-deps

    // useMemo(() => {
    //     if (isOnlyHospitalDashboard) {
    //         setAllFields(prevState => ({
    //             ...prevState,
    //             "validationType": ADT_VALIDATION_TYPES.ALL
    //         }));
    //     }
    // }, [isOnlyHospitalDashboard]);

    const checkFirstStepInput = () => {
        if (allFields.firstName && allFields.lastName && allFields.DOB && moment(allFields.DOB, "MM/DD/YYYY", true).isValid() && allFields.type) {
            if (allFields.type === "transfer" && !allFields.transferType) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }

    return (
        <>
            {validationInfo?.isOpen && !isValidationInfo && (
                <AlertDialog
                    maxWidth="xs"
                    onlyOkay={true}
                    content={validationInfo?.message}
                    handleClose={() => {
                        setIsValidationInfo(true)
                        setValidationInfo(null);
                    }}
                    handleSubmit={() => {
                        setIsValidationInfo(true)
                        setValidationInfo(null);
                    }}
                />
            )}
            {validation && validation.type && (
                <ValidationADT selectedItem={validation} close={() => resetValiDation()} />
            )}
            {showAddMissingADT && (
                <MissingADT
                    selectedItem={selectedItem}
                    isAskAdmissionDate={isAskAdmissionDate}
                    censusDate={censusDate}
                    checkQuestionsValidation={checkQuestionsValidation}
                    gotToDetails={gotToDetails}
                    isOnlyHospitalDashboard={isOnlyHospitalDashboard}
                    DBadmissionType={DBadmissionType}
                    patientAdded={props.patientAdded}
                    setIsAddOrReaddDialog={setIsAddOrReaddDialog}
                    isAddOrReaddDialog={isAddOrReaddDialog}
                    disabledTypes={
                        mostRecentADT && mostRecentADT.type === "transfer"
                            ? ["transfer"]
                            : ["admission", "readmission", "return", "admissionOrReadmission"]
                    }
                    initialFields={allFields}
                    close={() => {
                        setShowDuplicateADT(false);
                        setMostRecentADT(null);
                        setShowAddMissingADT(false);
                        props.close();
                    }}
                    added={() => setMostRecentADT(null)}
                />
            )}
            {showDuplicateADT && (
                <ShowDuplicateADT
                    allFields={allFields}
                    mostRecentADT={mostRecentADT}
                    setShowDuplicateADT={setShowDuplicateADT}
                    setShowAddMissingADT={setShowAddMissingADT}
                    setIsAddOrReaddDialog={setIsAddOrReaddDialog}
                />
            )}
            <div className={styles.overlay}></div>
            <div className={styles.addPatient}>
                <div className={`df aic ${styles.hdr}`}>
                    <p className={`df aic ffmar fw600 fs18`}>
                        {step > 2 ? (
                            props?.isMultipleFacility && step === 2 ? (
                                <span
                                    className={`m-r-15 ${styles.backWrpr}`}
                                    style={{ opacity: 0.3 }}
                                >
                                    <ExpandPopupSVG />
                                </span>
                            ) : (
                                <span
                                    className={`m-r-15 ${styles.backWrpr}`}
                                    onClick={() => {
                                        if (step === 2 && selectedItem == null) {
                                            setAllFields({});
                                        }
                                        // if (
                                        //     step === 3 &&
                                        //     selectedItem == null &&
                                        //     (allFields.type === "admission" || allFields.type === "readmission")
                                        // ) {
                                        //     setAllFieldsValue("type", "admissionOrReadmission");
                                        // }
                                        setStep(step - 1);
                                        setShowAddLatestAdmission(false);
                                    }}
                                >
                                    <ExpandPopupSVG />
                                </span>
                            )

                        ) : undefined}
                        <span className={`m-r-8 ${styles.lightText}`}>{selectedItem ? "Edit" : "Add"}</span>{" "}
                        {allFields.type && step >= 2 ? (
                            <span
                                className={`m-r-5`}
                                style={{ fontSize: "0.9em", fontStyle: "italic", opacity: 0.8 }}
                            >
                                {allFields.type === "transfer" && allFields.transferType
                                    ? allFields.transferType.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
                                        return str.toUpperCase();
                                    })
                                    : allFields.type.replace(/^./, function (str) {
                                        return str.toUpperCase();
                                    })}
                            </span>
                        ) : undefined}
                        {allFields.firstName && allFields.lastName && step === 3 ? (
                            <span style={{ fontSize: "0.9em" }}>
                                {` for `}
                                {`${allFields.firstName}${allFields.middleInitial ? ` ${allFields.middleInitial}` : ""
                                    } ${allFields.lastName}${allFields.suffix ? ` ${allFields.suffix}` : ""}`}
                            </span>
                        ) : step !== 2 ? (
                            <span className={styles.lightText}>ADT</span>
                        ) : undefined}{" "}
                    </p>
                    <div className={`mla`}>
                        <div className={`df acc ${styles.closeBtn}`} onClick={() => props.close()}>
                            <NewCloseSVG />
                        </div>
                    </div>
                </div>
                {/* <SelectType /> */}
                {step === 1 ? (
                    <SelectType
                        selectedItem={selectedItem}
                        allFields={allFields}
                        setAllFieldsValue={setAllFieldsValue}
                        gotToDetails={gotToDetails}
                    />
                ) : step === 2 ? (
                    <>
                        <div className={`p-t-20 p-r-20 p-b-0 p-l-20 df ffc ${styles.stepTwo}`}>

                            <BasicInfo
                                setAllFieldsValue={(type, val) => setAllFieldsValue(type, val)}
                                allFields={allFields}
                                isError={checkIsError("basicInfo")}
                                handleAddHighlight={handleAddHighlight}
                                handleValidate={handleValidate}
                            />

                            {mostRecentLoading &&
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <CircularProgress />
                                </Box>
                            }
                            {allFields.validationType && (
                                <SelectTypeNew
                                    selectedItem={selectedItem}
                                    allFields={allFields}
                                    setAllFieldsValue={setAllFieldsValue}
                                    gotToDetails={gotToDetails}
                                />
                            )}
                            <div className={`df aic ${styles.popupFtr} `}>
                                <div className={`mla`}>
                                    <LoadingButton

                                        loading={loading}
                                        disabled={loading || checkFirstStepInput()}
                                        size="medium"
                                        sx={{
                                            "&.Mui-disabled": {
                                                backgroundColor: "#f0f0f0 !important",  // Custom disabled background color
                                                color: "#a3a3a3 !important",  // Custom disabled text color
                                            },
                                            "& .MuiButton-endIcon": {
                                                color: "#a3a3a3",           // Custom color for the endIcon
                                            },
                                        }}
                                        onClick={async e => {
                                            if (checkBasicInfoValidation()) {
                                                console.log(1);
                                                e.preventDefault();
                                            } else {
                                                console.log(1.1);
                                                handleMostRecentADT();
                                            }
                                        }}
                                        className={`df aic ${styles.text}`}
                                        endIcon={<span className={`m-l-8`}>
                                            <BackArrowSVG fill="#a3a3a3" />
                                        </span>}
                                    >
                                        Next
                                    </LoadingButton>
                                </div>
                            </div>
                            {showAddLatestAdmission && (
                                <div className={styles.addLatest}>
                                    <div className={`df ffc p-t-20 p-l-20 p-r-20 p-b-20 ${styles.content}`}>
                                        <div className={`df aic`}>
                                            <p className={`df aic ffmm fs18`}>Please add latest admission</p>
                                            <div className={`mla`}>
                                                <div
                                                    className={`df acc ${styles.closeBtn}`}
                                                    onClick={() => setShowAddLatestAdmission(false)}
                                                >
                                                    <CloseIcon />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`df aic`}>
                                            <div className={`m-t-35 m-b-20 inputWrpr w100`}>
                                                <label className={`ffmr fs12`}>Latest admission Date</label>
                                                <Cleave
                                                    options={{ date: true, datePattern: ["m", "d", "Y"] }}
                                                    className={`ffml fs16`}
                                                    value={allFields.dateOfADT}
                                                    onChange={e => {
                                                        setLatestAdmission(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className={`df`}>
                                            <button
                                                className={`mla df aic ffmr fs14 ttuc ${styles.text}`}
                                                onClick={() => addLatestAdmission()}
                                            >
                                                Save Latest admission
                                                <span className={`m-l-8 m-t-4`}>
                                                    <BackArrowSVG fill="#a3a3a3" />
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                ) : step === 3 ? (
                    <>
                        {isAddOrReaddDialog && (
                            <AdmissionOrReadmissionSelect
                                allFields={allFields}
                                setAllFieldsValue={setAllFieldsValue}
                                gotToDetails={gotToDetails}
                                handleClose={(type = null) => {
                                    // if (type !== "save") {
                                    //     set
                                    // }
                                    setIsAddOrReaddDialog(false)
                                }}
                            />
                        )}
                        <div className={`p-t-20 p-r-20 p-b-0 p-l-20 df ffc ${styles.stepThree}`}>
                            <Questions
                                isAskAdmissionDate={isOnlyHospitalDashboard ? true : isAskAdmissionDate}
                                setAllFieldsValue={(type, val) => setAllFieldsValue(type, val)}
                                allFields={allFields}
                                mostRecentADT={mostRecentADT}
                                selectedItem={selectedItem}
                                setQuestions={setQuestions}
                                checkError={checkIsError("questions")}
                                isOnlyHospitalDashboard={isOnlyHospitalDashboard}
                                handleAddHighlight={handleAddHighlight}
                                setIsReadmissionValid={setIsReadmissionValid}
                                isReadmissionValid={isReadmissionValid}
                            />
                            <div className={`df aic ${styles.popupFtr} `}>
                                <div className={`df aic mla`}>
                                    <span className={`m-r-12`}>
                                        <LoadingButton
                                            variant='contained'
                                            loading={loading}
                                            size="medium"
                                            onClick={() => {
                                                add();
                                            }}
                                        >Save</LoadingButton>
                                    </span>
                                    {!props?.isMultipleFacility && (
                                        <>
                                            {allFields.wasReturned ? (
                                                <button
                                                    onClick={() => {
                                                        add();

                                                        // setStep(1);
                                                        setAllFields({
                                                            firstName: allFields.firstName,
                                                            lastName: allFields.lastName,
                                                            DOB: allFields.DOB,
                                                            middleInitial: allFields.middleInitial,
                                                            suffix: allFields.suffix,
                                                            type: allFields.wasAdmitted ? "admission" : "return",
                                                            transferType: undefined,
                                                        });
                                                    }}
                                                    className={`df aic ffmr fs14 ttuc m-r-10 `}
                                                >
                                                    Save
                                                    <span className={`m-l-10`} style={{ fontSize: "0.75em" }}>
                                                        and continue to{" "}
                                                        {allFields.wasAdmitted || allFields.wasAdmitted === undefined
                                                            ? "readmission"
                                                            : "return"}
                                                    </span>
                                                </button>
                                            ) : undefined}

                                            <button
                                                onClick={() => {
                                                    add(true);
                                                    setStep(1);
                                                    setAllFields({});
                                                    setShowAddLatestAdmission(false);
                                                }}
                                                className={`df aic ffmr fs14 ttuc`}
                                            >
                                                Save
                                                <span className={`m-l-10`} style={{ fontSize: "0.75em" }}>
                                                    and continue to add
                                                </span>
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                ) : undefined}
            </div>
            {isPriorHospitalization && (
                <ShowPriorHospitalInput
                    handleClose={() => {
                        setIsPriorDateOpened(false);
                        setIsPriorHospitalization(false)
                    }}
                    handleSave={() => {
                        setIsPriorHospitalization(false)
                    }}
                    setAllFieldsValue={setAllFieldsValue}
                    allFields={allFields}
                    name={"isHospitalPrior"}
                />
            )}
            {isHighLightInfo?.isOpen &&
                <AddQuestionHighlighter
                    selectedValue={isHighLightInfo?.selectedValue}
                    handleClose={() => setIsHighLightInfo({ isOpen: false, selectedValue: null })}
                    title={"Highlighted Question"}
                    handleOnSubmit={handleSaveHighlight}
                    handleDelete={handleDeleteHighlight}
                />}

        </>
    );
};

export default AddPatient;
