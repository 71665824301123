import { Paper, Tab, Tabs, Typography } from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import React from "react";
import ChangePassword from "../../components/profile/ChangePassword";
import UpdateProfile from "../../components/profile/UpdateProfile";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ManageProfilePage = () => {
  const [value, setValue] = React.useState(0);

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth={false}>
      <Stack direction={"row"} sx={{ padding: 3 }}>
        <Typography variant="h5">Manage Profile</Typography>
      </Stack>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ height: "100%", padding: 3 }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Manage Profile" {...a11yProps(0)} />
              <Tab label="Change Password" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <UpdateProfile />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ChangePassword />
          </TabPanel>
        </Paper>
      </Box>
    </Container>
  );
};

export default ManageProfilePage;
