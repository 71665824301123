import { Typography } from "@mui/material";
import { useCallback, useState } from "react";
import MinimizeSVG from "../../../assets/svgs/minimize.svg";
import PopupPlusSVG from "../../../assets/svgs/popup-plus.svg";
import styles from "./SelectableCard.module.scss";
import InfoIcon from "../../icon/InfoIcon";
import HtmlTooltip from "../../shared/HtmlTooltip";
import CardTooltipContent from "../card/CardTooltipContent";
import { IconButton } from "@mui/material";
import ReportButton from "../../shared/ReportButton";
import classNames from "classnames";
import CommonIconButtons from "../card/RightAction";

const SelectableCard = ({
	children,
	flex,
	title,
	minimizable,
	disabled,
	handleOnclickCard,
	selected,
	page = "",
	tooltipContent,
	handleOnClickAction,
	loading = false,
	handleOnClickReport,
	pageType
}) => {
	const [showDetails, setShowDetails] = useState(true);

	const handleClickOnDetailsSelectCard = useCallback(
		(totalTitle, defaultTab) => {
			if (!loading) {
				handleOnClickAction(totalTitle, defaultTab);
			}
		},
		[handleOnClickAction, loading]
	);

	const handleOnclickCardSelect = useCallback(
		(e) => {
			e.stopPropagation();
			if (!loading) {
				handleOnclickCard();
			}
		},
		[handleOnclickCard, loading]
	);

	return (
		<div
			className={classNames(
				styles.selectableCard,
				disabled && styles.disabledCard,
				selected && styles.selected,
				page && styles[page],
				loading && styles.skeleton,
				title === "Total +/-" && styles.overallTotalCard
			)}
			style={{ flex, height: `${showDetails ? "initial" : "fit-content"}` }}
			onClick={handleOnclickCardSelect}
		>
			<div className={`df aic ${styles.cardHeader}`}>
				<Typography className={`${styles.title}`} variant="subtitle1" component="p">
					{title}
				</Typography>
				<div className={`df aic mla w100`}>
					<div className={styles.tooltipWrapper}>
						{tooltipContent && (
							<div className={styles.htmlTooltip}>
								<HtmlTooltip content={!loading && <CardTooltipContent content={tooltipContent} />}>
									<IconButton
										disableFocusRipple={true}
										disableRipple={true}
										sx={{ cursor: loading ? "default" : "pointer" }}
									>
										<InfoIcon />
									</IconButton>
								</HtmlTooltip>
							</div>
						)}
						{loading ? (
							title !== "Total +/-" && (
								<div
									style={{ width: "24px", height: "24px", borderRadius: "50%", marginRight: "7px" }}
									className="skeleton"
								/>
							)
						) : (
							<ReportButton
								handleOnClickReport={handleOnClickReport}
								loading={loading}
								title={title}
								sx={{
									width: 23,
									height: 23,
								}}								
								openFrom="card"
								pageType={pageType}
							/>
						)}
						{handleOnClickAction && (
							<CommonIconButtons
								title={title}
								handleOnClickAction={(title, defaultTab) => handleClickOnDetailsSelectCard(title, defaultTab)}
								loading={loading}
							/>
						)}
					</div>
					{minimizable ? (
						<div
							className={`p-l-9 p-r-5 p-t-5 p-b-5 m-l-15`}
							style={{ cursor: "pointer" }}
							onClick={() => setShowDetails(!showDetails)}
						>
							{showDetails ? <MinimizeSVG /> : <PopupPlusSVG />}
						</div>
					) : undefined}
				</div>
			</div>
			{showDetails ? <div className={`df aic ${styles.cardContent}`}>{children}</div> : undefined}
		</div>
	);
};

export default SelectableCard;
