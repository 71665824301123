import * as React from "react";
import { IconButton, Stack, Tooltip } from "@mui/material";
import NotesIcon from '@mui/icons-material/Notes';
import SelectedDateContainer from "./SelectedDateContainer";
import DetailsCardTab from "./DetailsCardTab";
import ReportButton from "../ReportButton";
import CustomExcelReportColumnDialog from "./CustomExcelReportColumnDialog";
import { createSetting } from "../../../services/settings.service";
import { PAGE_TYPE } from "../../../types/pages.type";
import { useDispatch, useSelector } from "react-redux";
import { setIsOpenNote } from "../../../store/reducers/common.slice";

export default function ChartDetailsTab({
    queryFilters,
    selectedTab,
    handleTabButton,
    handleOnClickReport,
    loading,
    handleSendAutomaticallyReport,
    handleExcelReport,
    tableFilterTypeOptions,
    setSelectedFacility,
    selectedFacility,
    questions,
    tableFilterType,
    tableFilterTypeSetting,
    pageType,
    subPageType
}) {
    const [isCustomerColumn, setIsCustomerColumn] = React.useState(false);
    const [selectedQuestions, setSelectedQuestions] = React.useState([]);
    const dispatch = useDispatch();
    const { isNotePopup } = useSelector((state) => state.common);

    React.useEffect(() => {
        if (questions && questions.length > 0) {
            setSelectedQuestions(questions);
        }
        // eslint-disable-next-line
    }, [questions, pageType]);

    const handleSaveExcelColumn = React.useCallback((selectedColumns) => {
        createSetting({
            selectedColumns,
            selectedTab: tableFilterType || tableFilterTypeSetting,
            pageType: subPageType === PAGE_TYPE.ADT ? subPageType : pageType,
            ...subPageType === PAGE_TYPE.ADT && { subPageType }
        });
    }, [tableFilterType, pageType, tableFilterTypeSetting, subPageType]);

    const handleExcelReportCustom = React.useCallback((e) => {
        handleExcelReport(e);
        handleSaveExcelColumn(e)
    }, [handleExcelReport, handleSaveExcelColumn]);

    const handleOnclickOpenNotes = React.useCallback(() => {
        dispatch(setIsOpenNote(!isNotePopup));
    }, [isNotePopup, dispatch]);

    return (
        <>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={0}
            >
                <Stack item direction={"row"} spacing={1}>
                    <DetailsCardTab
                        selectedTab={selectedTab}
                        handleTabButton={handleTabButton}
                        tableFilterTypeOptions={tableFilterTypeOptions}
                        setSelectedFacility={setSelectedFacility}
                        selectedFacility={selectedFacility}
                    />
                </Stack>
                <Stack>
                    <Stack direction={"row"}>
                        {!loading && (
                            <Tooltip title={"Notes"} arrow>
                                <IconButton
                                    sx={{
                                        width: 29,
                                        height: 29,
                                        mr: 1,
                                        mt: "-6px",
                                        borderRadius: "50%",
                                        border: "1px solid grey",
                                        backgroundColor: "#fff",
                                        color: "primary.contrastText",
                                        ":hover": { backgroundColor: "grey", color: "#fff" },
                                    }}
                                    disableFocusRipple={true}
                                    disableRipple={false}
                                    id="composition-button"
                                    aria-haspopup="true"
                                    onClick={handleOnclickOpenNotes}
                                >
                                    <NotesIcon sx={{
                                        fontSize: 18,
                                        color: "#000"
                                    }} />
                                </IconButton>
                            </Tooltip>
                        )}

                        <ReportButton
                            handleSaveExcelColumn={handleSaveExcelColumn}
                            handleOnClickReport={handleOnClickReport}
                            handleSendAutomaticallyReport={handleSendAutomaticallyReport}
                            loading={loading}
                            title={"report"}
                            sx={{ mt: "-6px", width: 29, height: 29 }}
                            selectedTab={selectedTab}
                            selectedTabSetting={tableFilterType || tableFilterTypeSetting}
                            handleExcelReport={handleExcelReport}
                            handleExcelReportCustom={() => { setIsCustomerColumn(true) }}
                            tableFilterType={tableFilterType}
                            selectedQuestions={selectedQuestions}
                            pageType={pageType}
                            subPageType={subPageType}
                            openFrom="card"
                        />
                        <SelectedDateContainer queryFilters={queryFilters} />
                    </Stack>
                </Stack>
            </Stack>
            {isCustomerColumn &&
                <CustomExcelReportColumnDialog
                    pageType={pageType}
                    subPageType={subPageType}
                    selectedTab={tableFilterType || tableFilterTypeSetting}
                    selectedQuestions={selectedQuestions}
                    handleClose={() => setIsCustomerColumn(false)}
                    handleSave={(e) => {
                        setIsCustomerColumn(false);
                        handleExcelReportCustom(e)
                    }}
                />
            }
        </>
    );
}
