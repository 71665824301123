import React, { useEffect, useState } from "react";
import { Box, Checkbox, FormControlLabel, Grid, Stack, Typography } from "@mui/material";
import styles from "../ChartDialogCommon.module.scss";
import ChartFilterButtons from "./ChartFilterButtons";
import ChartArrowButton from "./ChartArrowButton";
import LineChartDialog from "./LineChartDialog";
import ChartTotalContainer from "./ChartTotalContainer";
import { useSelector } from "react-redux";

export default function CardDetailChart({
	data,
	handleClickFilter,
	selectedFilter,
	queryFilters,
	queryFiltersComparison,
	filters,
	setQueryFilters,
	setQueryFiltersComparison,
	reloadChartData,
	total,
	censusAverage,
	handleChartLabelType,
	isChartTotalButton = false,
	totalText,
	censusSubtitle = "Of Avg. Census",
	isTotalText = true,
	isChartArrow = true,
	selectedFacility,
	isOverallTotal = false,
	dbData,
	isPlusSign = false,
}) {
	const [chartData, setChartData] = useState([]);
	const [isSeparateLine, setIsSeparateLine] = useState(false);
	const { filterButtons, chartPointType } = queryFilters;
	const activeFacilities = useSelector((state) => state.activeFacilities.facilities);

	useEffect(() => {
		setChartData(data);
	}, [data]);

	return (
		<Box container className={styles.chartBoxContainer}>
			<div className={styles.title}>&nbsp;</div>
			<Grid container className={styles.boxContainerInner}>
				<Grid item xs={1} alignItems="center" container>
					{isChartArrow && (
						<ChartArrowButton
							queryFilters={queryFilters}
							setQueryFilters={setQueryFilters}
							queryFiltersComparison={queryFiltersComparison}
							setQueryFiltersComparison={setQueryFiltersComparison}
							selectedFilter={selectedFilter}
							type="left"
							filters={filters}
							reloadChartData={reloadChartData}
						/>
					)}
				</Grid>
				<Grid
					item
					xs={10}
					sx={{
						position: "relative",
					}}
				>
					<>
						{isTotalText && (
							<Stack justifyContent={"flex-end"} position={"absolute"} sx={{ right: -50, top: -25 }}>
								<ChartTotalContainer
									total={total}
									chartData={chartData}
									censusAverage={censusAverage}
									handleChartLabelType={handleChartLabelType}
									title={totalText}
									subtitle={censusSubtitle}
									isChartTotalButton={isChartTotalButton}
									chartPointType={chartPointType}
									isTotalText={isTotalText}
									dbData={dbData}
								/>
							</Stack>
						)}
						{activeFacilities &&  Array.isArray(activeFacilities) &&  activeFacilities.length > 1 && (
							<Stack sx={{ mb: -2, ml: 2.5, width:"220px" }} direction={"row"}>
								<FormControlLabel
									control={
										<Checkbox
											disableRipple
											checked={isSeparateLine}
											onChange={(e) => setIsSeparateLine(e.target.checked)}
										/>
									}
									label={
										<Typography
											variant="subtitle2"
											component={"span"}
											sx={{ fontFamily: "mont reg" }}
											fontSize={14}
										>
											Per Facility View
										</Typography>
									}
								/>
							</Stack>
						)}
						<Stack sx={{ mt: isTotalText ? "25px" : "18px", width: "100%" }} direction={"row"}>
							<ChartFilterButtons
								handleClickFilter={handleClickFilter}
								selectedFilter={selectedFilter}
								filterButtons={filterButtons}
							/>
						</Stack>
						<LineChartDialog
							selectedFacility={selectedFacility}
							chartData={chartData}
							chartPointType={chartPointType}
							selectedFilter={selectedFilter}
							isSeparateLine={isSeparateLine}
							censusAverage={censusAverage}
							isOverallTotal={isOverallTotal}
							isPlusSign={isPlusSign}
						/>
					</>
				</Grid>
				<Grid item xs={1} alignItems="center" container className={styles.rightButtonContainer}>
					{isChartArrow && (
						<ChartArrowButton
							queryFilters={queryFilters}
							selectedFilter={selectedFilter}
							type="right"
							filters={filters}
							setQueryFilters={setQueryFilters}
							reloadChartData={reloadChartData}
						/>
					)}
				</Grid>
			</Grid>
		</Box>
	);
}
