import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import SearchIcon from "@mui/icons-material/Search";
import { Checkbox, ClickAwayListener, IconButton, InputAdornment, TextField, Tooltip, Typography } from "@mui/material";
import classNames from "classnames";
import { Children, cloneElement, useEffect, useState } from "react";
import MinimizeSVG from "../../../assets/svgs/minimize.svg";
import PopupPlusSVG from "../../../assets/svgs/popup-plus.svg";
import InfoIcon from "../../icon/InfoIcon";
import HtmlTooltip from "../../shared/HtmlTooltip";
import ReportButton from "../../shared/ReportButton";
import styles from "./Card.module.scss";
import CardTooltipContent from "./CardTooltipContent";
import CommonIconButtons from "./RightAction";

const Card = ({
	children,
	flex,
	title,
	secondaryTitle = "",
	secondaryTitleError = "",
	secondaryTitleClassName = "",
	minimizable,
	itemAlign,
	isDetailsOpen = true,
	isDisabled = false,
	handleOnClickAction,
	taggedList,
	loading = false,
	isSlider = false,
	tooltipContent = undefined,
	isMinimized,
	priorityNumber = null,
	setIsMinimized = () => void 0,
	handleOnClickReport,
	style,
	styleContent,
	canBeSearched,
	data,
	selectedItems,
	handleToggleAll,
	type,
	debug,
	pageType,
	cardType = null
}) => {
	const [showDetails, setShowDetails] = useState(false);
	const usePropsMinimizeStatus = isMinimized !== undefined;
	const showStatus = usePropsMinimizeStatus ? isMinimized : showDetails;
	const [showSearch, setShowSearch] = useState(false);
	const [searchValue, setSearchValue] = useState("");

	const numberOfCheckedItems = data?.reduce((a, v) => {
		const itemId = v._id ? v._id : v.id;
		const isInSelectedItems = selectedItems.find((x) => x === itemId);
		if (isInSelectedItems) {
			return a + 1;
		}
		return a;
	}, 0);
	const allItemsChecked = numberOfCheckedItems === data?.length;

	useEffect(() => {
		setShowDetails(isDisabled ? false : isDetailsOpen);
	}, [isDetailsOpen, isDisabled]);

	const childrenWithProps = children
		? Children.map(children, (child) =>
			cloneElement(child, {
				searchValue,
			})
		)
		: null;

	return (
		<div
			className={`${classNames(styles.card, isDisabled && styles.disabled)} dashboard-card-view `}
			style={{ flex, height: `${showStatus || loading ? "100%" : "fit-content"}`, ...style }}
		>
			<div className={(classNames("ffmar fs15 fw700 "), styles.cardHeaderContainer)}>
				<div className={classNames("df aic ffmar fs15 fw700", styles.cardHeader)}>
					{showSearch && canBeSearched && !loading ? (
						<ClickAwayListener
							mouseEvent="onMouseDown"
							touchEvent="onTouchStart"
							onClickAway={() => {
								showSearch && setShowSearch(false);
							}}
						>
							<TextField
								placeholder="Search"
								type="search"
								variant="outlined"
								fullWidth
								size="small"
								value={searchValue}
								onChange={(e) => {
									setSearchValue(e.target.value);
								}}
								sx={{ height: "29px" }}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<SearchIcon />
										</InputAdornment>
									),
								}}
							/>
						</ClickAwayListener>
					) : (
						<>
							{canBeSearched && (
								<Tooltip title="Search" arrow>
									<IconButton
										disableFocusRipple
										disableRipple
										sx={{
											width: 22,
											height: 21,
											mr: 1.2,
											borderRadius: "50%",
											border: loading ? "none" : "2px solid #637381",
											backgroundColor: loading ? "transparent" : searchValue !== "" ? "#4879f5" : "#fff",
											color: loading ? "inherit" : "black",
										}}
										onClick={() => !loading && setShowSearch(true)}
										className={loading && "skeleton"}
									>
										{!loading && <SearchIcon sx={{ width: "16px", height: "14px" }} />}
									</IconButton>
								</Tooltip>
							)}
							{canBeSearched && ((minimizable && isMinimized) || !minimizable) && (
								<Tooltip title="Select/Clear all" arrow>
									<Checkbox
										disableRipple
										edge="end"
										icon={<CheckCircleOutlineIcon />}
										checkedIcon={<CheckCircleIcon />}
										onChange={() => handleToggleAll({ items: data, type, cardTitle: title })}
										checked={allItemsChecked && !loading}
										disabled={loading}
										sx={{ p: 0, mr: "5px", pl: 0, ml: "-7px", borderRadius: "10000px" }}
									/>
								</Tooltip>
							)}

							<div className={styles.cardTooltipIcon}>
								<HtmlTooltip content={!loading && <CardTooltipContent content={tooltipContent} />}>
									<IconButton
										disableFocusRipple={true}
										disableRipple={true}
										sx={{ cursor: loading ? "default" : "pointer" }}
									>
										<InfoIcon />
									</IconButton>
								</HtmlTooltip>
							</div>

							<Typography className={styles.title} variant="subtitle1" component="div" sx={{ maxWidth: "50%", fontSize: { xs: "0.8rem", md: "0.8rem" } }}>
								{title} &nbsp; &nbsp;<b>{priorityNumber && `#${priorityNumber}`}</b>
								{secondaryTitle && <div className={classNames("fs10", styles.secondaryTitle)}>{secondaryTitle}</div>}
								{secondaryTitleError && (
									<div className={classNames("fs10", styles.secondaryTitle, secondaryTitleClassName)}>
										{secondaryTitleError}
									</div>
								)}
							</Typography>

							<div className={`df aic mla`}>
								{handleOnClickReport &&
									(loading ? (
										<div
											style={{
												width: "22px",
												height: "22px",
												borderRadius: "50%",
												marginRight: "7px",
											}}
											className="skeleton"
										></div>
									) : (
										<ReportButton
											cardType={cardType || type}
											handleOnClickReport={handleOnClickReport}
											title={title}
											sx={{
												width: 23,
												height: 23,
											}}
											pageType={pageType}
											openFrom="card"
										/>
									))}
								{!isDisabled && (
									<CommonIconButtons
										title={title}
										handleOnClickAction={handleOnClickAction}
										loading={loading}
									/>
								)}
								{minimizable && (
									<div
										className="p-l-9 p-r-5 p-t-5 p-b-5 m-l-15"
										style={{ cursor: "pointer" }}
										onClick={() =>
											usePropsMinimizeStatus ? setIsMinimized(!isMinimized) : setShowDetails(!showDetails)
										}
									>
										{showStatus ? <MinimizeSVG /> : <PopupPlusSVG />}
									</div>
								)}
							</div>
						</>
					)}
				</div>
				{taggedList && taggedList}
			</div>
			{!isSlider && showStatus && isDisabled === false && (
				<div
					className={classNames("df", styles.cardContent, itemAlign ? itemAlign : "aic")}
					{...(styleContent && { style: styleContent })}
				>
					{childrenWithProps}
				</div>
			)}
			{isSlider && showStatus && isDisabled === false && childrenWithProps}
		</div>
	);
};

export default Card;
