import update from "immutability-helper";
import _ from "lodash";
import moment from "moment";
import { store } from "..";
import { percentageByEnum } from "../data/common.data";
import { TOTAL_TYPE } from "../types/common.type";
import { ACCOUNT_PERCENTAGE_BY, OVERALL_PAGE_SUB_TYPE, PAGE_TYPE } from "../types/pages.type";

export const updateChartFilterByPriority = async (cardFilter, type) => {
	let latestCardFilter = cardFilter;
	const selectedPriority = _.find(cardFilter.priorityData, { type: type });
	if (selectedPriority) {
		let idx = cardFilter.priorityData.findIndex((elem) => elem.type === type);
		if (idx !== -1) {
			let selectedCardPriority = [];
			let selectedFilters = [];
			if (cardFilter.priorityData.length > 1) {
				selectedCardPriority = cardFilter.priorityData.slice(0, idx);
				selectedFilters = _.map(selectedCardPriority, "type");
			}
			let updatedFilter = Object();
			for await (const [key, value] of Object.entries(cardFilter)) {
				if (_.includes(selectedFilters, key)) {
					updatedFilter[key] = value;
				} else {
					updatedFilter[key] = [];
				}
			}
			latestCardFilter = updatedFilter;
		}
	}
	return latestCardFilter;
};

export const ADTpriorityFilter = async (priorityDataFilter, childId, cardId, latestAdmitFilters = []) => {
	const selectedFilter = _.find(latestAdmitFilters, { childId, cardId });

	const totalChecked = selectedFilter?.selectedIds?.length || 0;
	let priorityData = [];
	const selectedPriority = _.find(priorityDataFilter, { childId, cardId });
	if (selectedPriority) {
		const idx = priorityDataFilter.findIndex((p) => p.childId === childId && p.cardId === cardId);

		if (totalChecked > 0) {
			priorityData = update(priorityDataFilter, {
				[idx]: { $set: selectedFilter },
			});
		} else {
			priorityData = update(priorityDataFilter, {
				$splice: [[idx, 1]],
			});
		}
	} else {
		if (totalChecked > 0) {
			priorityData = update(priorityDataFilter, { $push: [selectedFilter] });
		}
	}
	return priorityData;
};

export const priorityFilter = async (priorityDataFilter, type, totalChecked, cardTitle) => {
	let priorityData = [];
	const selectedPriority = _.find(priorityDataFilter, { type });
	if (selectedPriority) {
		const idx = priorityDataFilter.findIndex((p) => p.type === type);
		if (totalChecked > 0) {
			let priObject = { type, priority: 1, selectedChk: totalChecked, cardTitle };
			priorityData = update(priorityDataFilter, { [idx]: { $set: priObject } });
		} else {
			priorityData = update(priorityDataFilter, {
				$splice: [[idx, 1]],
			});
		}
	} else {
		if (totalChecked > 0) {
			let priObject = { type, priority: 1, selectedChk: totalChecked, cardTitle };
			priorityData = update(priorityDataFilter, { $push: [priObject] });
		}
	}
	return priorityData;
};

export const admissionPriorityFilter = async (
	priorityDataFilter,
	cardFilterPart,
	existingFilters,
	filterType,
	cardTitle = null
) => {
	let priorityData = [];
	if (cardFilterPart && cardFilterPart.type) {
		let type = cardFilterPart.type;
		const selectedPriority = _.find(priorityDataFilter, { type });
		if (selectedPriority) {
			const idx = priorityDataFilter.findIndex((p) => p.type === type);
			if (existingFilters > 0) {
				let priObject = {
					type,
					priority: 1,
					selectedChk: existingFilters,
					filterType,
					cardTitle,
				};
				priorityData = update(priorityDataFilter, {
					[idx]: { $set: priObject },
				});
			} else {
				priorityData = update(priorityDataFilter, {
					$splice: [[idx, 1]],
				});
			}
		} else {
			if (existingFilters > 0) {
				let priObject = {
					type,
					priority: 1,
					selectedChk: existingFilters,
					filterType,
					cardTitle,
				};
				priorityData = update(priorityDataFilter, { $push: [priObject] });
			}
		}
	} else {
		let childId = cardFilterPart.childId;
		let cardId = cardFilterPart.cardId;
		let latestAdmitFilters = existingFilters;
		let selectedFilter = _.find(latestAdmitFilters, { childId, cardId });
		if (selectedFilter) {
			selectedFilter = Object.assign({}, selectedFilter, { filterType });
		}
		const totalChecked = selectedFilter?.selectedIds?.length || 0;
		const selectedPriority = _.find(priorityDataFilter, { childId, cardId });
		if (selectedPriority) {
			const idx = priorityDataFilter.findIndex((p) => p.childId === childId && p.cardId === cardId);
			if (totalChecked > 0) {
				priorityData = update(priorityDataFilter, {
					[idx]: { $set: selectedFilter },
				});
			} else {
				priorityData = update(priorityDataFilter, {
					$splice: [[idx, 1]],
				});
			}
		} else {
			if (totalChecked > 0) {
				priorityData = update(priorityDataFilter, { $push: [selectedFilter] });
			}
		}
	}
	return priorityData;
};

export function projectionPerMonth(total, filter, projectionDays = 30) {
	let projections = 0;
	const { startDate, endDate } = filter;
	var a = moment.utc(startDate).startOf("day");
	var b = moment.utc(endDate).endOf("day");
	let days = b.diff(a, "days") + 1;
	//days = days === 0 ? 1 : (days + 1);
	if (days <= 30) {
		//projectionDays = 30
		projections = (projectionDays * total) / days;
	} else {
		//projectionDays = 30
		const months = days / projectionDays;
		projections = total / months;
	}
	return projections ? Math.round(projections) : 0;
}

export function itemPercentageChart(total, mainTotalVal = null) {
	let percentage = 0;
	if (total !== 0 && mainTotalVal !== 0) {
		percentage = (total / mainTotalVal) * 100;
	}
	percentage = percentage ? percentage : 0;

	return `${percentage.toFixed(2)}%`;
}

export function itemPercentage(total, mainTotalVal = null, type, page = "hospital", forComparison) {
	let selectedTotal = 0;
	if (!mainTotalVal) {
		if (page === PAGE_TYPE.HOSPITAL) {
			const { mainTotal } = store.getState().hospital;
			selectedTotal = mainTotal;
		}
		if (page === PAGE_TYPE.ADMISSION) {
			const { mainTotal } = store.getState().admission;
			selectedTotal = mainTotal;
		}
		if (page === PAGE_TYPE.COMMUNITY_TRANSFER) {
			const { mainTotal } = store.getState().communityTransfer;
			selectedTotal = mainTotal;
		}
		if (page === PAGE_TYPE.DECEASED) {
			const { mainTotal } = store.getState().deceased;
			selectedTotal = mainTotal;
		}
		if (page === PAGE_TYPE.OVERALL) {
			const { mainTotal } = store.getState().overall;
			selectedTotal = mainTotal;
		}
	} else {
		selectedTotal = mainTotalVal;
	}
	let percentage = 0;

	if (forComparison && total === mainTotalVal) {
		percentage = 0;
	} else if (total !== 0 && selectedTotal !== 0) {
		percentage = (total / selectedTotal) * 100;
	}
	percentage = percentage ? percentage : 0;
	if (type && type === "number") {
		return percentage < 1 ? percentage.toFixed(1) : percentage.toFixed();
	}
	if (type && type === "percentage") {
		return `${percentage.toFixed()}%`;
	}
	return `(${percentage.toFixed()}%)`;
}

export function matchedArray(array1, array2) {
	return _.intersectionWith(array1, array2, _.isEqual);
}

export function getMatchedId(array1, array2) {
	let resArr = [];
	_.intersectionWith(array1, array2, (a, b) => {
		if (a._id === b) resArr = [...resArr, ...a.ids];
	});
	return resArr;
}

export function getMatchedCount(array1, array2, type) {
	let totalCount = 0;
	_.intersectionWith(array1, array2, (a, b) => {
		if (a._id === b) totalCount++;
	});
	return { [type + "Total"]: totalCount };
}

export async function getArrayIds(array1) {
	let resArr = [];
	array1.map((item) => {
		if (item.ids.length > 0) {
			resArr = [...resArr, ...item.ids];
		}
		return true;
	});
	return resArr;
}

export async function checkArrayPermission(checkedPermission) {
	let response = false;
	const { permissions, user } = store.getState();
	const intersection = matchedArray(permissions, checkedPermission);
	if (intersection.length > 0 || user?.role.slug === "super") {
		response = true;
	} else {
		response = false;
	}
	return response;
}

export async function getChartFilterId(type, filter) {
	return { type, ids: filter[type] };
}

export function percentageLabel(percentage, type = "bracket") {
	percentage = percentage ? percentage : 0;
	if (type === "percentage") {
		return `${percentage}%`;
	} else {
		return `(${percentage}%)`;
	}
}

//This function are used for nineteen days analysis
export async function updateListTotalValue(listData, matchedIds, type = "total", totalPatients = 0, totalFilter) {
	const { totalType } = totalFilter;
	if (listData && listData.length > 0) {
		const latestData = listData.map((RItem) => {
			const intersection = matchedArray(matchedIds, RItem.ids);
			let obj = {
				[type]: intersection.length || 0,
				ids: intersection,
				percentage: itemPercentage(intersection.length, totalPatients, "number"),
			};
			if (totalType === TOTAL_TYPE.MAIN) {
				obj.originalTotal = intersection.length || 0;
				obj.isTooltip = false;
			} else {
				obj.originalTotal = RItem?.originalTotal || 0;
				obj.isTooltip = true;
			}
			return Object.assign({}, RItem, obj);
		});
		return latestData;
	}
}

export async function filterListDataItems(dataGroupBy, type, totalPatient, totalFilter) {
	const { originalData = [], totalType = null } = totalFilter;

	let listGroup = [];
	if (dataGroupBy) {
		for await (const [key, value] of Object.entries(dataGroupBy)) {
			const valueArr = value[0];
			if (key && valueArr && valueArr[type]) {
				let object = Object();
				object._id = key;
				object.id = key;
				object.label = valueArr[type].label;
				object.name = valueArr[type].label;
				object.total = value.length;
				object.value = value.length;
				let original = originalData[key] ? originalData[key]?.length : 0;
				object.originalTotal = original;
				object.isTooltip = totalType && totalType === TOTAL_TYPE.MAIN ? false : true;
				object.percentage = itemPercentage(value.length, totalPatient, "number");
				listGroup.push(object);
			}
		}
		if (listGroup.length > 0) {
			listGroup = _.orderBy(listGroup, "total", "desc");
		}
	}
	return listGroup;
}

export const getPositivePlusSign = (value) => {
	if (value > 0) return `+${value}`;
	return `${value}`;
};

export const getDaysBetweenDates = (filter) => {
	const start = new Date(filter.startDate);
	const end = new Date(filter.endDate);

	const timeDiff = Math.abs(end - start);
	const parsedDiff = Math.round(timeDiff / (1000 * 60 * 60 * 24)) + 1;

	return parsedDiff;
};

export const updateCurrentFilterListData = (currentFilterListdata, latestFilterListData) => {
	const currentFilterListDataCopy = { ...currentFilterListdata };
	for (const key in latestFilterListData) {
		if (currentFilterListDataCopy.hasOwnProperty(key)) {
			currentFilterListDataCopy[key] = latestFilterListData?.[key] ?? [];
		}
	}
	return currentFilterListDataCopy;
};

export const areArraysDifferent = (arr1, arr2) => {
	if (arr1.length !== arr2.length) {
		return true; // Arrays are different if lengths are not equal
	}
	return !arr1.every((item) => arr2.includes(item)); // Check if every item in arr1 is in arr2
};

export const calcProPercentsBasedOnFilterAndDays = (value, filter, projectionDays, isPercentage, scale) => {
	if ((scale && isPercentage) || !isPercentage) {
		const days = getDaysBetweenDates(filter);
		const projectionDaysParsed = projectionDays === 0 || !projectionDays ? days : projectionDays;
		const scalingFactor = projectionDaysParsed / days;
		if (filter?.transferType === OVERALL_PAGE_SUB_TYPE.TOTAL) {
			const scaledValue = Number(value) * scalingFactor;
			const result = scaledValue < 1 ? scaledValue : Math.round(scaledValue);
			return result > 0 ? `+${result}` : result;
		}
		return (Number(value) * scalingFactor) && (Number(value) * scalingFactor) < 1 ? (Number(value) * scalingFactor) : Math.round(Number(value) * scalingFactor);
	} else {
		return value;
	}
};

export const getPercentageByTotal = (total, pageType = null) => {
	const { selectedAccount } = store.getState().common;
	if (selectedAccount && selectedAccount?.percentageBy === ACCOUNT_PERCENTAGE_BY.BED) {
		return total?.bedCapacity;
	} else {
		if (pageType === PAGE_TYPE.HOSPITAL) {
			const { percentageAgainst } = store.getState().hospital;
			return percentageAgainst;
		} else if (pageType === PAGE_TYPE.COMMUNITY_TRANSFER) {
			const { percentageAgainst } = store.getState().communityTransfer;
			return percentageAgainst;
		} else {
			return total?.censusAverage;
		}
	}
};

export const getPercentageByType = (type = null) => {
	const { selectedAccount } = store.getState().common;
	if (selectedAccount && selectedAccount?.percentageBy === ACCOUNT_PERCENTAGE_BY.BED) {
		return type ? ACCOUNT_PERCENTAGE_BY.BED : _.capitalize(selectedAccount?.percentageBy);
	} else {
		return type ? ACCOUNT_PERCENTAGE_BY.CENSUS : _.capitalize(ACCOUNT_PERCENTAGE_BY.CENSUS);
	}
};

export const isOnlyHospitalTabAccess = () => {
	const { selectedAccount } = store.getState().common;
	if (selectedAccount && selectedAccount?.dashboardAccess && selectedAccount?.dashboardAccess.length === 1) {
		return true;
	} else {
		return false;
	}
};

export function getTooltipTitle(facilities, activeFacilities, type = "one") {
	// eslint-disable-next-line array-callback-return
	if (facilities && facilities === "refreshing") return null;
	const sFacilityNames = facilities?.map((facility) => {
		if (_.includes(type === "all" ? activeFacilities : activeFacilities.slice(1), facility._id)) {
			return facility?.name;
		}
		return undefined;
	})
		.filter((ele) => ele)
		.join(", ");

	return sFacilityNames;
}

export function getChartFacilityPercentageBy(response) {
	const { customPercentageLabel, percentageBy } = store.getState().common;

	if (percentageBy === percentageByEnum.bedCapacity) {
		return response?.bedByFacility || [];
	} else if (percentageBy === percentageByEnum.censusAverage) {
		return response?.censusByFacility || []
	} else if (percentageBy === percentageByEnum.censusAsOfNow) {
		return response?.censusAsOfNowByFacility || []
	} else if (percentageBy === customPercentageLabel) {
		const { customePercentages } = store.getState().common;
		let percentageNumber = _.find(customePercentages, { label: customPercentageLabel })?.customPercentage ?? 0;
		return response?.censusByFacility?.map((ele) => {
			return { ...ele, total: percentageNumber }
		});
	} else {
		return response?.censusByFacility || []
	}
}

export function getDynamicPercentageBy(dbData, percentageByType = null, isOnlyHospitalDashboard = false) {
	let percentageNumber = dbData?.censusAverage;
	const { percentageBy, customPercentageLabel } = store.getState().common;

	let type = percentageByType ? percentageByType : percentageBy;

	if (type === percentageByEnum.bedCapacity) {
		percentageNumber = dbData?.bedCapacity;
	} else if (type === percentageByEnum.censusAsOfNow && !isOnlyHospitalDashboard) {
		percentageNumber = dbData?.censusAsOfNow;
	} else if (type === percentageByEnum.customNumber) {
		const { customPercentage } = store.getState().common;
		percentageNumber = customPercentage;
	} else if (type === customPercentageLabel) {
		const { customePercentages } = store.getState().common;
		percentageNumber = _.find(customePercentages, { label: customPercentageLabel })?.customPercentage ?? 0;
	}

	return percentageNumber;
}

export function updateOrAddToArray(array, newObject, identifierKey) {
	if (!array || array.length === 0) return [newObject];

	const index = array?.findIndex(item => item[identifierKey] === newObject[identifierKey]);

	if (index !== -1) {
		// Object exists, update it
		array[index] = { ...array[index], ...newObject };
	} else {
		// Object doesn't exist, add it
		array.push(newObject);
	}

	return array;
}

export const patientDataOrderBy = (patientData, order, orderBy) => {
	return _.orderBy(patientData,
		[
			(item) => {
				// Handle primary sort
				if (orderBy === 'DOB' || orderBy === 'dateOfADT' || orderBy === 'dateOfLatestAdmission') {
					return new Date(item[orderBy]); // Convert to Date object for comparison
				}
				return item[orderBy]; // For other fields
			},
			(item) => new Date(item?.dateOfADT) // Always sort by dateOfADT as secondary
		],
		[order, 'asc'] // Primary order direction and secondary is always ascending
	)
}